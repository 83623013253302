import { isoToHumanReadable } from '../utils/calendar';
import { Vehicle } from '../types/vehicle';
import { Event } from '../types/event';

export interface TrackEvent {
	id: number;
	lng: number;
	lat: number;
	type: string;
	value: string | null;
	startAddress?: string | null;
	endAddress?: string | null;
	duration: number | null;
	timestamp: string | null;
	vehicle: {
		id: number;
		speedLimit: number;
		idlingDuration: number;
		parkingDuration: number;
	} | null;
	geofence?: {
		id: number;
		name: string;
	} | null;
}

export interface VehicleForTrack {
	id: number;
	speedLimit: number;
	idlingDuration: number;
	parkingDuration: number;
}

export interface TrackDTO {
	sliderIndex: number;
	id: number;
	lng: number;
	lat: number;
	status: string | null;
	altitude: number;
	angle: number;
	soc: number;
	dte: number;
	// satellites: number;
	speed: string;
	distance: number;
	ignitionStatus: boolean | number;
	movementStatus: boolean | number;
	gnssStatus: string | number;
	batteryLevel: number;
	startEvents?: TrackEvent[] | null;
	endEvents?: TrackEvent[] | null;
	relay: boolean | null;
	timestamp: string;
	totalOdometer: number;
	vehicle: VehicleForTrack | null;
}

export interface PolylineForTrack {
	coordinates: TrackDTO[] | [];
	typeOfPolyline?: string;
}

export function createTrackDTO(data: Vehicle): [TrackDTO[], PolylineForTrack[], TrackEvent[]] | undefined {
	const polylines: PolylineForTrack[] = [];
	let tracks: TrackDTO[] = [];
	const allAllowedEvents: TrackEvent[] = [];
	const allCoordinates: TrackDTO[] = [];
	let checkOverSpeed: boolean | null = null;
	let sliderIndex = 0;

	if (data.iotData && data.iotData.length !== 0) {
		let distance = 0.0;
		let polyline: PolylineForTrack = {
			coordinates: [],
			typeOfPolyline: 'normal',
		};

		data.iotData.forEach((track, index) => {
			const trackDistance = getDistance(
				data.iotData[index - 1]?.latitude,
				data.iotData[index - 1]?.longitude,
				track?.latitude,
				track?.longitude
			);
			const trackObj: TrackDTO = {
				sliderIndex: sliderIndex,
				id: data.id,
				lng: track?.longitude ? track.longitude : 0,
				lat: track?.latitude ? track.latitude : 0,
				status: track?.status ? track?.status : null,
				altitude: track?.altitude ? track.altitude : 0,
				angle: track?.angle ? track.angle : 0,
				speed: track?.speed ? track.speed.toFixed(1) : '0',
				distance: distance + trackDistance,
				ignitionStatus: track?.ignitionStatus ? track.ignitionStatus : -1,
				movementStatus: track?.movementStatus ? track.movementStatus : -1,
				gnssStatus: track?.gnssStatus ? track.gnssStatus : -1,
				batteryLevel: track?.externalPowerVoltage ? track.externalPowerVoltage : 0,
				startEvents: track?.startEvents?.length
					? track.startEvents.map((startEvent: Event) => {
							return {
								id: startEvent.id,
								type: startEvent.type,
								value: startEvent?.value ? startEvent.value : null,
								startAddress: startEvent?.startAddress ? startEvent.startAddress : null,
								duration: startEvent?.duration ? startEvent.duration : null,
								geofence: startEvent.geofence
									? {
											id: startEvent.geofence.id,
											name: startEvent.geofence.name,
										}
									: null,
								lng: track?.longitude ? track.longitude : 0,
								lat: track?.latitude ? track.latitude : 0,
								timestamp: track?.timestamp ? isoToHumanReadable(track.timestamp) : null,
								vehicle: track?.vehicle
									? {
											id: track.vehicle.id,
											speedLimit: track.vehicle.speedLimit ? track.vehicle.speedLimit : 0,
											idlingDuration: track.vehicle.idlingDuration ? track.vehicle.idlingDuration : 0,
											parkingDuration: track.vehicle.parkingDuration ? track.vehicle.parkingDuration : 0,
										}
									: null,
							};
						})
					: null,
				endEvents: track?.endEvents?.length
					? track.endEvents.map((endEvent: Event) => {
							return {
								id: endEvent.id,
								type: endEvent.type,
								value: endEvent?.value ? endEvent.value : null,
								startAddress: endEvent?.startAddress ? endEvent.startAddress : null,
								duration: endEvent?.duration ? endEvent.duration : null,
								geofence: endEvent.geofence
									? {
											id: endEvent.geofence.id,
											name: endEvent.geofence.name,
										}
									: null,
								lng: track?.longitude ? track.longitude : 0,
								lat: track?.latitude ? track.latitude : 0,
								timestamp: track?.timestamp ? isoToHumanReadable(track.timestamp) : null,
								vehicle: track?.vehicle
									? {
											id: track.vehicle.id,
											speedLimit: track.vehicle.speedLimit ? track.vehicle.speedLimit : 0,
											idlingDuration: track.vehicle.idlingDuration ? track.vehicle.idlingDuration : 0,
											parkingDuration: track.vehicle.parkingDuration ? track.vehicle.parkingDuration : 0,
										}
									: null,
							};
						})
					: null,
				relay: track?.relayStatus ? track.relayStatus : null,
				timestamp: track?.timestamp ? track.timestamp : '0',
				// tripOdometer: track?.tripOdometer ? track.tripOdometer : 0,
				totalOdometer: track?.totalOdometer ? track.totalOdometer : 0,
				soc: track?.soc ? track.soc : 0,
				dte: track?.dte ? track.dte : 0,
				// tripStatus: track?.tripStatus ? track.tripStatus : null,
				vehicle: track?.vehicle
					? {
							id: track.vehicle.id,
							speedLimit: track.vehicle.speedLimit ? track.vehicle.speedLimit : 0,
							idlingDuration: track.vehicle.idlingDuration ? track.vehicle.idlingDuration : 0,
							parkingDuration: track.vehicle.parkingDuration ? track.vehicle.parkingDuration : 0,
						}
					: null,
			};
			if (track.startEvents.length !== 0) {
				track.startEvents.forEach((event: Event) => {
					if (event.type === 'OverSpeed') checkOverSpeed = true;
				});
			}
			if (track.endEvents.length !== 0) {
				track.endEvents.forEach((event: Event) => {
					if (event.type === 'OverSpeed') checkOverSpeed = false;
				});
			}

			if (trackDistance > 250) {
				if (polyline.typeOfPolyline !== 'jump') {
					const prevPolylineLastCoordinate = tracks[tracks.length - 1];
					// push older polyline to the polyline array
					polyline.coordinates = tracks;

					if (polyline.coordinates.length > 1) polylines.push(polyline);

					polyline = {
						coordinates: [],
					};

					polyline.typeOfPolyline = 'jump';
					tracks = [];
					if (prevPolylineLastCoordinate !== undefined) tracks.push(prevPolylineLastCoordinate);
				}
			} else if (!track.ignitionStatus && track.speed > 0) {
				if (polyline.typeOfPolyline !== 'looseWiring') {
					const prevPolylineLastCoordinate = tracks[tracks.length - 1];
					// push older polyline to the polyline array
					polyline.coordinates = tracks;

					if (polyline.coordinates.length > 1) polylines.push(polyline);

					polyline = {
						coordinates: [],
					};

					polyline.typeOfPolyline = 'looseWiring';
					tracks = [];

					if (prevPolylineLastCoordinate !== undefined) tracks.push(prevPolylineLastCoordinate);
				}
			} else if (checkOverSpeed) {
				if (polyline.typeOfPolyline !== 'overspeed') {
					const prevPolylineLastCoordinate = tracks[tracks.length - 1];
					// push older polyline to the polyline array
					polyline.coordinates = tracks;

					if (polyline.coordinates.length > 1) polylines.push(polyline);

					polyline = {
						coordinates: [],
					};

					polyline.typeOfPolyline = 'overspeed';
					tracks = [];
					if (prevPolylineLastCoordinate !== undefined) tracks.push(prevPolylineLastCoordinate);
				}
			} else {
				if (polyline.typeOfPolyline !== 'normal') {
					const prevPolylineLastCoordinate = tracks[tracks.length - 1];
					// push older polyline to the polyline array
					polyline.coordinates = tracks;

					if (polyline.coordinates.length > 1) polylines.push(polyline);

					polyline = {
						coordinates: [],
					};

					polyline.typeOfPolyline = 'normal';
					tracks = [];
					if (prevPolylineLastCoordinate !== undefined) tracks.push(prevPolylineLastCoordinate);
				}
			}
			tracks.push(trackObj);

			//all coordinates for video playback
			allCoordinates.push(trackObj);

			//events marker
			if (track?.startEvents?.length !== 0) {
				track.startEvents.map((startEvent: Event) => {
					if (
						startEvent?.type === 'Ignition' ||
						startEvent?.type === 'Relay' ||
						startEvent?.type === 'Ac' ||
						startEvent?.type === 'Geofence' ||
						startEvent?.type === 'Main Power' ||
						startEvent?.type === 'Idling' ||
						startEvent?.type === 'OverSpeed' ||
						startEvent?.type === 'SOS' ||
						startEvent?.type === 'Parking'
					) {
						allAllowedEvents.push({
							id: startEvent.id,
							lng: track?.longitude ? track.longitude : 0,
							lat: track?.latitude ? track.latitude : 0,
							// status: event?.status !== undefined ? event.status : null,
							value: startEvent.value ? startEvent.value : null,
							timestamp: track?.timestamp ? isoToHumanReadable(track.timestamp) : null,
							type: startEvent.type,
							startAddress: startEvent?.startAddress ? startEvent.startAddress : null,
							duration: startEvent?.duration ? startEvent.duration : null,
							geofence: startEvent.geofence
								? {
										id: startEvent.geofence.id,
										name: startEvent.geofence.name,
									}
								: null,
							vehicle: track?.vehicle
								? {
										id: track.vehicle.id,
										speedLimit: track.vehicle.speedLimit ? track.vehicle.speedLimit : 0,
										idlingDuration: track.vehicle.idlingDuration ? track.vehicle.idlingDuration : 0,
										parkingDuration: track.vehicle.parkingDuration ? track.vehicle.parkingDuration : 0,
									}
								: null,
						});
					}
				});
			}
			if (track?.endEvents?.length !== 0) {
				track.endEvents.map((endEvent: Event) => {
					if (
						endEvent?.type === 'Ignition' ||
						endEvent?.type === 'Relay' ||
						endEvent?.type === 'Ac' ||
						endEvent?.type === 'Geofence' ||
						endEvent?.type === 'Main Power' ||
						endEvent?.type === 'Idling' ||
						endEvent?.type === 'OverSpeed' ||
						endEvent?.type === 'SOS' ||
						endEvent?.type === 'Parking'
					) {
						allAllowedEvents.push({
							id: endEvent.id,
							lng: track?.longitude ? track.longitude : 0,
							lat: track?.latitude ? track.latitude : 0,
							// status: event?.status !== undefined ? event.status : null,
							value: endEvent.value ? endEvent.value : null,
							timestamp: track?.timestamp ? isoToHumanReadable(track.timestamp) : null,
							type: endEvent.type,
							endAddress: endEvent?.endAddress ? endEvent.endAddress : null,
							duration: endEvent?.duration ? endEvent.duration : null,
							geofence: endEvent.geofence
								? {
										id: endEvent.geofence.id,
										name: endEvent.geofence.name,
									}
								: null,
							vehicle: track?.vehicle
								? {
										id: track.vehicle.id,
										speedLimit: track.vehicle.speedLimit ? track.vehicle.speedLimit : 0,
										idlingDuration: track.vehicle.idlingDuration ? track.vehicle.idlingDuration : 0,
										parkingDuration: track.vehicle.parkingDuration ? track.vehicle.parkingDuration : 0,
									}
								: null,
						});
					}
				});
			}
			distance = trackDistance !== null ? distance + trackDistance : 0;
			sliderIndex++;
			checkOverSpeed = false;
		});

		polyline.coordinates = tracks;

		if (polyline.coordinates.length > 1) polylines.push(polyline);
		tracks = [];

		return [allCoordinates, polylines, allAllowedEvents];
	}
}

export function getDistance(prevLat: number, prevLng: number, currLat: number, currLng: number): number {
	let distance = 0;

	if (!prevLat || !prevLng || !currLat || !currLng) {
		return distance;
	}

	const r = 6371000;
	const p = Math.PI / 180;

	const a =
		0.5 -
		Math.cos((currLat - prevLat) * p) / 2 +
		(+Math.cos(prevLat * p) * Math.cos(currLat * p) * (1 - Math.cos((currLng - prevLng) * p))) / 2;

	distance = 2 * r * Math.asin(Math.sqrt(a));

	return distance;
}
