import React, { useEffect, useRef, useState } from 'react'; // Components
import { Node } from 'react-checkbox-tree';
import { Icon, Label, Popup } from 'semantic-ui-react';
import { BsFillFuelPumpFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import Calendar from '../../../../components/calendar/Calendar';
import SearchBox from '../../../../components/searchBox/SearchBox';
import Shortcuts from '../../../../components/sidebar/shortcuts/Shortcuts';
import VehicleFilters from '../../../../components/sidebar/filters/VehicleFilters';
import { Battery, DTE, SOC } from '../../../../components/vehicles/info-window/CustomInfoWindowStyles';
import { Content, FuelIcon, Header, Status } from '../../../../components/STYLED/VehicleListStyles';
import OrganisationWiseVehicleList from '../../../../components/sidebar/organisation-view/OrganisationWiseVehicleList';
import { VEHICLE_STATUS } from '../../../../constants/constants';
import { THEME } from '../../../../constants/Theme';
import Store from '../../../../redux/store';
import { RootState } from '../../../../redux/RootState';
import { isoToHumanReadable } from '../../../../utils/calendar';
import { getVehicleIcon } from '../../../../utils/common';
import { dispatch, dispatchNoPayload } from '../../../../helper/view specific/store';
import { MarkerInfo, MarkerInfoRow } from '../../../../components/STYLED/InfoWindow';
import { updateVehicleStatus } from '../../../../helper/view specific/vehicleStatus';
import { UnSubscribeVehicleFromFirebase } from '../../../../helper/services/firebase';
import { EdgeButton } from './SidebarStyles';
import { Vehicle } from '../../../../types/vehicle';
import { Organisation } from '../../../../types/organisation';
import Driver from '../../../../assets/images/driver.png';
import Recalculation from '../../../../assets/images/recalculation.png';
import { ResizableHandle, SidebarContainer } from '../../../../components/STYLED/Containers';
import PopUpBox from '../../../../components/sidebar/PopUp';

function SideBarNew2() {
	const sidebarData = useSelector((state: RootState) => state.ui.sidebarVisible);
	const allVehiclesDetails = useSelector((state: RootState) => state.vehicle.vehicles);
	const vehicleState = useSelector((state: RootState) => state.vehicleSlice.entities);
	const selectedVehicleData = useSelector((state: RootState) => state.sidebar.selectedVehicles);
	const selectedVehicleFromGraph = useSelector((state: RootState) => state.sidebar.selectedVehicleFromGraph);
	const { vehicleGroups, organisations, vehicleDetails } = useSelector((state: RootState) => state.vehicle);
	const { filter, searchQuery, selectedVehicles } = useSelector((state: RootState) => state.sidebar);

	const [isGroupView] = useState<boolean>(false);
	const [isOrgView] = useState<boolean>(false);
	const [allVehiclesChecked, setAllVehiclesChecked] = useState<string>('none');
	const [checked, setChecked] = useState<string[]>([]);
	const [unSelected, setUnSelected] = useState<string[]>([]);
	const [expanded, setExpanded] = useState<string[]>([]);
	const [organisationsView, setOrganisationsView] = useState<Node[]>([]);
	const [allVehicles, setAllVehicles] = useState<string[]>([]);
	const [totalVehiclesCount, setTotalVehiclesCount] = useState<number>(0);
	const [labelColor, setLabelColor] = useState<string>('darkblue');
	const [prevSearch, setPrevSearch] = useState<string>('');

	const allVehiclesRef = useRef<string[]>([]);
	const sidebarRef = useRef<HTMLElement | null>(null);
	const resizingRef = useRef(false);

	const isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true';

	let timeoutId: NodeJS.Timeout | null = null;

	useEffect(() => {
		unSelectedVehicle(allVehiclesDetails);
		return () => {
			unSelectedVehicle(allVehiclesDetails);
			findIdAndUnSubscribe(unSelected);
		};
	}, [unSelected, checked]);

	useEffect(() => {
		dispatch('EVENT_DATA_STORE', []);
		dispatch('SUMMARY_DATA_STORE', []);
		dispatch('TRACK_DATA_STORE', []);
		dispatch('TRIP_DATA_STORE', []);
	}, [selectedVehicleData]);

	useEffect(() => {
		const labelColor: string = findLabelColor(filter);
		setLabelColor(labelColor);
		updateView();
	}, [isGroupView, isOrgView, filter, selectedVehicleFromGraph, searchQuery, allVehiclesChecked, vehicleState]);

	useEffect(() => {
		return () => {
			Store.dispatch({
				type: 'SET_SELECTED_VEHICLES',
				payload: [],
			});
			Store.dispatch({
				type: 'SET_SELECTED_VEHICLES_NUMBERS',
				payload: [],
			});
			dispatch('SET_FILTER', 'all');
			dispatchNoPayload('CLEAR_SEARCH_QUERY');
		};
	}, []);

	const findLabelColor = (filter: string) => {
		if (filter === 'moving') return 'green';
		else if (filter === 'idle') return 'yellow';
		else if (filter === 'stopped') return 'grey';
		else if (filter === 'noDataOneHour') return 'orange';
		else if (filter === 'noDataOneDay') return 'red';
		else if (filter === 'noData') return 'black';
		return 'darkblue';
	};

	function checkForIndeterminate(checked: string[]) {
		let count = 0;
		allVehicles.forEach((vehicle) => {
			if (checked.includes(vehicle)) count++;
		});
		if (count === allVehicles.length) setAllVehiclesChecked('all');
		else if (!count) setAllVehiclesChecked('none');
		else setAllVehiclesChecked('few');
	}

	function setSelectedVehicles(checked: string[]) {
		const selectedVehiclesNumbers: string[] = [];
		const data = checked.map((vehicleID: string) => {
			const id: number = Number(vehicleID.split('-')[0]);
			const vehicleDetail = vehicleDetails.get(id);
			if (vehicleDetail?.data) {
				const registrationNumber = vehicleDetail.data.registrationNumber;
				selectedVehiclesNumbers.push(registrationNumber);
			}
			return id;
		});
		const uniqueVehicles = new Set(data);
		const uniqueRegistrationNumbers = new Set(selectedVehiclesNumbers);
		Store.dispatch({
			type: 'SET_SELECTED_VEHICLES',
			payload: Array.from(uniqueVehicles),
		});
		Store.dispatch({
			type: 'SET_SELECTED_VEHICLES_NUMBERS',
			payload: Array.from(uniqueRegistrationNumbers),
		});
	}

	function onAllVehiclesClick() {
		if (allVehiclesChecked !== 'all') {
			setChecked(allVehicles);
			setSelectedVehicles(allVehicles);
			setAllVehiclesChecked('all');
		} else {
			setChecked([]);
			setSelectedVehicles([]);
			setAllVehiclesChecked('none');
		}
	}

	function findIdAndUnSubscribe(vehicleIds: string[]) {
		vehicleIds.map((vehicleId: string) => {
			const id: number = parseInt(vehicleId.split('-')[0]);
			const regNo = vehicleDetails.get(id)?.data?.registrationNumber;
			UnSubscribeVehicleFromFirebase(`${id}-${regNo}`);
		});
	}

	const unSelectedVehicle = (allVehicles: Vehicle[]) => {
		const unCheckedVehicle = allVehicles.filter((value) => !selectedVehicles.includes(value.id));
		const id = unCheckedVehicle.map((obj: Vehicle) => obj.id);
		Store.dispatch({
			type: 'SET_UNSELECTED_VEHICLES',
			payload: id,
		});
	};

	function updateView() {
		const labelCountColor = findLabelColor(filter);

		const searchExpanded: string[] = [];
		const leafNodes: string[] = [];

		let data: Organisation[] = [];
		const orgId = localStorage.getItem('organisation');
		const orgName = localStorage.getItem('organisationName');
		if (organisations) data = organisations;
		else if (orgId && orgName) {
			data.push({
				id: parseInt(orgId),
				name: orgName,
				vehicleGroups: vehicleGroups,
			});
		}

		let totalVhCount = 0;
		const organisationsViewArr: Node[] = data.flatMap((organisation) => {
			const totalOrgVehicleCount = new Set();

			searchExpanded.push(`${organisation.id}`);

			// Check if organization name and vehicle group name are the same
			const orgAndGroupAreSame = organisation.name === organisation.vehicleGroups[0].name;

			const vehicleGroupChild = organisation.vehicleGroups.flatMap((vehicleGroup) => {
				searchExpanded.push(`${vehicleGroup.id}-${organisation.id}`);

				const vehicleChild = vehicleGroup.vehicles.flatMap((vehicle) => {
					const nodeValue = `${vehicle.id}-${vehicleGroup.id}-${organisation.id}`;
					const vehicleFilterStatus = vehicleState[vehicle.id]?.filterStatus;

					if (
						(vehicleState[vehicle.id] &&
							vehicleFilterStatus &&
							(vehicleFilterStatus === filter || selectedVehicles.includes(vehicle.id))) ||
						filter === 'all'
					) {
						searchExpanded.push(nodeValue);
						leafNodes.push(nodeValue);

						if (
							!searchQuery ||
							(searchQuery &&
								(vehicle.registrationNumber
									.toLowerCase()
									.replaceAll(' ', '')
									.includes(searchQuery.toLowerCase().replaceAll(' ', '')) ||
									vehicleGroup.name
										.toLowerCase()
										.replaceAll(' ', '')
										.includes(searchQuery.toLowerCase().replaceAll(' ', '')))) ||
							organisation.name
								.toLowerCase()
								.replaceAll(' ', '')
								.includes(searchQuery.toLowerCase().replaceAll(' ', ''))
						) {
							if (selectedVehicleFromGraph) {
								setChecked([nodeValue]);
								setSelectedVehicles([nodeValue]);
								dispatchNoPayload('CLEAR_VEHICLE_SELECTED_FROM_GRAPH');
							}
							totalOrgVehicleCount.add(vehicle.id);
							return {
								value: nodeValue,
								label: vehicleNode(vehicle),
								className: 'leafView',
							};
						} else {
							return [];
						}
					} else {
						return [];
					}
				});

				if ((!searchQuery && vehicleChild.length) || (searchQuery && vehicleChild.length)) {
					totalVhCount += vehicleChild.length;
					return {
						value: `${vehicleGroup.id}-${organisation.id}`,
						label: groupNode(vehicleGroup.name, vehicleChild.length, labelCountColor),
						className: 'groupView',
						children: vehicleChild,
					};
				} else return [];
			});

			if ((!searchQuery && vehicleGroupChild.length) || (searchQuery && vehicleGroupChild.length)) {
				if (orgAndGroupAreSame) {
					return {
						value: `${organisation.id}`,
						label: orgNode(organisation.name, vehicleGroupChild[0].children.length, labelCountColor),
						className: 'orgView',
						children: vehicleGroupChild[0].children,
					};
				}
				return {
					value: `${organisation.id}`,
					label: orgNode(organisation.name, totalOrgVehicleCount.size, labelCountColor),
					className: 'orgView',
					children: vehicleGroupChild,
				};
			} else {
				return [];
			}
		});

		setOrganisationsView(organisationsViewArr);
		setTotalVehiclesCount(totalVhCount);

		setAllVehicles(leafNodes);
		if (allVehiclesRef?.current) allVehiclesRef.current = leafNodes;
		if (searchQuery !== prevSearch && searchQuery.length !== 0) {
			setExpanded(searchExpanded);
		} else if (searchQuery !== prevSearch && searchQuery.length === 0) {
			setExpanded([]);
		}
		setPrevSearch(searchQuery);
	}

	function orgNode(name: string, count: number, labelCountColor: string | undefined) {
		return (
			<>
				<span style={{ whiteSpace: 'normal' }} title={name}>
					{name}
				</span>
				<div style={{ justifyContent: 'space-around', display: 'flex' }}>
					<Label className={`labelColor-${labelCountColor}`} circular basic size="tiny">
						{count}
					</Label>
					{isSuperAdmin && <PopUpBox componentType={'organisation'} />}
				</div>
			</>
		);
	}

	function groupNode(name: string, count: number, labelCountColor: string | undefined) {
		return (
			<>
				<span>{name}</span>
				<div style={{ justifyContent: 'space-around' }}>
					<Label
						basic
						circular
						size="tiny"
						className={`labelColor-${labelCountColor}`}
						style={{ borderStyle: 'dashed', borderWidth: 'thin' }}>
						{count}
					</Label>
					{isSuperAdmin && <PopUpBox componentType={'vehicleGroup'} />}
				</div>
			</>
		);
	}

	const getVehicleType = (vehicleType: string) => {
		return vehicleType === 'Car'
			? 'Car'
			: vehicleType === 'Bike'
				? 'Bike'
				: vehicleType === 'Truck'
					? 'Truck'
					: vehicleType === 'Construction'
						? 'Construction Machinery'
						: vehicleType === 'Bus'
							? 'Bus'
							: vehicleType === 'Generator'
								? 'Generator'
								: vehicleType === 'Container'
									? 'Container'
									: vehicleType === 'Mini Truck'
										? 'Mini Truck'
										: vehicleType === 'Auto'
											? 'Auto'
											: 'Car';
	};

	function vehicleNode(vehicle: Vehicle) {
		const vehicleDetail = vehicleDetails.get(vehicle.id);
		const location = vehicleState[vehicle.id]?.location;
		const vehicleStatus = location ? updateVehicleStatus(location) : '';
		const vehicleType = vehicleDetail?.data?.type;
		return (
			<>
				{vehicleDetail?.data?.status !== 'Paused' && vehicleDetail?.data?.status !== 'Breakdown' ? (
					<Content>
						<Header>
							<span>{vehicle.registrationNumber}</span>
							<div style={{ display: 'flex' }}>
								<RecalculationPopUp id={vehicle.id} />
								{vehicleDetail?.data?.driver && (
									<Popup
										trigger={<img src={Driver} alt={'icon'} />}
										content={
											<div style={{ fontWeight: 'bold' }}>
												<p>{`Driver: ${vehicleDetail?.data?.driver?.name}`}</p>
												<p>{`Phone: ${
													vehicleDetail?.data?.driver?.phone ? vehicleDetail?.data?.driver?.phone : 'Not Available'
												}`}</p>
											</div>
										}
									/>
								)}
								{vehicleDetail?.data?.isFMSVehicle && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<BsFillFuelPumpFill size={'16px'} color={'orange'} />
									</div>
								)}
								<img title={getVehicleType(vehicleType || '')} src={getVehicleIcon(vehicleType || '')} alt="Icon" />
								{isSuperAdmin && (
									<PopUpBox registrationNumber={vehicle.registrationNumber} id={vehicle.id} componentType={'vehicle'} />
								)}
							</div>
						</Header>

						<Status
							color={
								vehicleStatus === 'GPS not fixed'
									? THEME.COLORS.vehicleNoDataHalfHour
									: vehicleStatus === 'No data since'
										? THEME.COLORS.vehicleNoDataHalfHour
										: vehicleStatus === 'Moving'
											? THEME.COLORS.vehicleMoving
											: vehicleStatus === 'Idle'
												? THEME.COLORS.vehicleIdle
												: vehicleStatus === 'Ignition off'
													? THEME.COLORS.vehicleIgnitionOff
													: THEME.COLORS.vehicleNoDataHalfHour
							}>
							{vehicleStatus !== 'No data since' && vehicleStatus}
						</Status>

						<MarkerInfo>
							{location && (
								<>
									<MarkerInfoRow>
										<span>Speed {Number(location.speed).toFixed(2)} km/h</span>
									</MarkerInfoRow>
									{vehicleState &&
										Object.hasOwn(vehicleState, vehicle.id) &&
										location?.externalPowerVoltage !== undefined && (
											<Battery>Battery {(location?.externalPowerVoltage / 1000).toFixed(2)} Volts</Battery>
										)}

									{location?.soc !== null && location?.soc !== 0 && <SOC>SOC {location?.soc} %</SOC>}

									{location?.dte !== null && location?.dte !== 0 && (
										<DTE>DTE {(location?.dte / 1000).toFixed(2)} kms</DTE>
									)}

									{location.timestamp && (
										<MarkerInfoRow>
											{vehicleStatus === 'No data since' ? (
												<span style={{ color: THEME.COLORS.vehicleNoDataHalfHour }}>
													{`Last data ${isoToHumanReadable(location.timestamp)}`}
												</span>
											) : (
												<span>{isoToHumanReadable(location.timestamp)}</span>
											)}
										</MarkerInfoRow>
									)}
								</>
							)}
						</MarkerInfo>
					</Content>
				) : (
					<Content>
						<Header>
							<span>{vehicle.registrationNumber}</span>
							<img title={getVehicleType(vehicleType || '')} src={getVehicleIcon(vehicleType || '')} alt="Icon" />
						</Header>
						<MarkerInfo>
							<MarkerInfoRow>
								<Status color={THEME.COLORS.vehicleNoDataOneDay}>
									{vehicleDetail?.data?.status === 'Paused'
										? 'Service Stopped; Please Contact Support.'
										: vehicleDetail?.data?.status === 'Breakdown'
											? 'Vehicle is Under Breakdown'
											: ' '}
								</Status>
							</MarkerInfoRow>
						</MarkerInfo>
					</Content>
				)}

				{(vehicleDetail?.data?.sensors?.includes('fuel_obd_litre') ||
					vehicleDetail?.data?.sensors?.includes('fuel_obd_percentage')) && (
					<FuelIcon>
						<Icon name="tint" color="orange" />
					</FuelIcon>
				)}
			</>
		);
	}

	const RecalculationPopUp = ({ id }: { id: number }) => {
		const recalculationQueue = vehicleState[id]?.recalculationQueue;
		if (recalculationQueue) {
			return (
				<Popup
					trigger={<img src={Recalculation} alt={'icon'} />}
					wide
					size={'small'}
					content={
						<span style={{ fontWeight: 'bold' }}>
							{`Data will be recalculated from ${isoToHumanReadable(recalculationQueue)}`}
						</span>
					}
				/>
			);
		} else return <></>;
	};

	const toggleSidebar = () => {
		Store.dispatch({
			type: 'SHOW_SIDEBAR',
			payload: !sidebarData,
		});
	};

	const handleMouseDown = () => {
		resizingRef.current = true;
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	};

	const handleMouseMove = (e: MouseEvent) => {
		if (!resizingRef.current) return;

		const sidebar = sidebarRef.current;
		const newWidth = Math.min(Math.max(e.clientX, 350), window.innerWidth);

		if (sidebar?.style) sidebar.style.width = `${newWidth}px`;
	};

	const handleMouseUp = () => {
		if (!resizingRef.current) return;

		resizingRef.current = false;
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	};

	useEffect(() => {
		resizingRef.current = false;
	}, []);

	return (
		<>
			{sidebarData && (
				<SidebarContainer sidebarVisible={sidebarData} ref={sidebarRef} onMouseDown={handleMouseDown}>
					{/*Calendar*/}
					<Calendar />

					<Shortcuts />
					<EdgeButton
						title={'collapse'}
						top={'120px'}
						right={'-20px'}
						bgColor={'#1b3c71'}
						bgColoronhover={'#8badd3'}
						color={'#fff'}
						className="circular ui icon button"
						onClick={toggleSidebar}>
						<i className="angle left icon"></i>
					</EdgeButton>

					{/*Filters*/}
					<VehicleFilters
						allVehicleSelect={() => {
							dispatch('SET_FILTER', 'all');
						}}
						movingVehicleSelect={() => {
							dispatch('SET_FILTER', VEHICLE_STATUS.Moving);
						}}
						idleVehicleSelect={() => {
							dispatch('SET_FILTER', VEHICLE_STATUS.Idle);
						}}
						stoppedVehicleSelect={() => {
							dispatch('SET_FILTER', VEHICLE_STATUS.Stopped);
						}}
						noDataOneHourVehicleSelect={() => {
							dispatch('SET_FILTER', VEHICLE_STATUS.NoDataOneHour);
						}}
						noDataOneDayVehicleSelect={() => {
							dispatch('SET_FILTER', VEHICLE_STATUS.NoDataOneDay);
						}}
						noDataVehicleSelect={() => {
							dispatch('SET_FILTER', VEHICLE_STATUS.NoData);
						}}
					/>

					{/*Search Box*/}
					<SearchBox
						placeholder={
							isGroupView
								? 'Search by vehicle number or group name'
								: 'Search by vehicle number or group name or organisation name'
						}
						title={
							isGroupView
								? 'Search by vehicle number or group name'
								: 'Search by vehicle number or group name or organisation name'
						}
						onClearIconClick={() => {
							dispatchNoPayload('CLEAR_SEARCH_QUERY');
							dispatchNoPayload('CLEAR_VEHICLE_SELECTED_FROM_GRAPH');
						}}
						onChange={(e) => {
							if (timeoutId) {
								clearTimeout(timeoutId);
							}

							timeoutId = setTimeout(() => {
								dispatch('SET_SEARCH_QUERY', e.target.value === '' ? '' : e.target.value.toLowerCase().trim());
							}, 500);
						}}
					/>

					{/*Select All  Check Box*/}

					{/*/!*Vehicle List*!/*/}

					<OrganisationWiseVehicleList
						handleAllVehiclesCheck={onAllVehiclesClick}
						isAllVehiclesCheck={allVehiclesChecked}
						allVehiclesList={allVehicles}
						allVehicleColor={labelColor}
						total={totalVehiclesCount}
						checkedList={checked}
						expandedList={expanded}
						viewList={organisationsView}
						handleOnCheck={(total, targetNode) => {
							const allVehicles = [];
							const breakDownVehicles = [];
							const toBeCheckedVehicles = [];
							const selectedNodes = targetNode;
							const selectedNodesChildren = selectedNodes?.children;
							const selectedNodesParent = selectedNodes?.parent;

							if (selectedNodesParent && Object.keys(selectedNodesParent).length === 0 && selectedNodesChildren) {
								for (let i = 0; i < selectedNodesChildren.length; i++) {
									const isOrgNameAndVGNameSame = selectedNodesChildren[0].value.split('-').length === 3;

									if (!isOrgNameAndVGNameSame) {
										const vgArray = selectedNodesChildren[i];
										if (vgArray.children) {
											for (let j = 0; j < vgArray.children?.length; j++) {
												const id = parseInt(vgArray.children[j].value.split('-')[0]);
												const currentVehicleDetail = vehicleDetails.get(id);

												if (
													currentVehicleDetail?.data?.status !== 'Breakdown' &&
													currentVehicleDetail?.data?.status !== 'Paused'
												)
													toBeCheckedVehicles.push(vgArray.children[j].value);
												else breakDownVehicles.push(vgArray.children[j].value);
												allVehicles.push(vgArray.children[j].value);
											}
										}
									} else {
										const id = parseInt(selectedNodesChildren[i].value.split('-')[0]);
										const currentVehicleDetail = vehicleDetails.get(id);

										if (
											currentVehicleDetail?.data?.status !== 'Breakdown' &&
											currentVehicleDetail?.data?.status !== 'Paused'
										) {
											toBeCheckedVehicles.push(selectedNodesChildren[i].value);
										} else {
											breakDownVehicles.push(selectedNodesChildren[i].value);
										}
										allVehicles.push(selectedNodesChildren[i].value);
									}
								}
							} else if (selectedNodesChildren && selectedNodesParent && selectedNodesParent.className === 'orgView') {
								const separatedStrings = targetNode?.value.split('-');

								if (separatedStrings.length < 3) {
									for (let i = 0; i < selectedNodesChildren?.length; i++) {
										const id = parseInt(selectedNodesChildren[i].value.split('-')[0]);
										const currentVehicleDetail = vehicleDetails.get(id);
										if (
											currentVehicleDetail?.data?.status !== 'Breakdown' &&
											currentVehicleDetail?.data?.status !== 'Paused'
										)
											toBeCheckedVehicles.push(selectedNodesChildren[i].value);
										else breakDownVehicles.push(selectedNodesChildren[i].value);
										allVehicles.push(selectedNodesChildren[i].value);
									}
								} else {
									toBeCheckedVehicles.push(targetNode.value);
								}
							} else if (selectedNodes?.children === undefined) {
								const id = parseInt(selectedNodes.value.split('-')[0]);
								const currentVehicleDetail = vehicleDetails.get(id);

								if (
									currentVehicleDetail?.data?.status !== 'Breakdown' &&
									currentVehicleDetail?.data?.status !== 'Paused'
								)
									toBeCheckedVehicles.push(selectedNodes.value);
								else breakDownVehicles.push(selectedNodes.value);
								allVehicles.push(selectedNodes.value);
							}

							if (targetNode.checked) {
								const temp = [...checked, ...toBeCheckedVehicles];
								const nodes = new Set(temp);

								setChecked(Array.from(nodes));
								setSelectedVehicles(Array.from(nodes));
								checkForIndeterminate(Array.from(nodes));
							} else {
								const temp: string[] = [targetNode.value];
								const unselectedNodes = new Set(temp);
								findIdAndUnSubscribe([...Array.from(unSelected), ...Array.from(unselectedNodes)]);
								setUnSelected([...Array.from(unSelected), ...Array.from(unselectedNodes)]);

								setChecked(total);
								setSelectedVehicles(total);
								checkForIndeterminate(total);
							}
						}}
						handleOnExpand={(expanded: string[]) => {
							setExpanded(expanded);
						}}
						handleOnClick={(clicked) => {
							const id = parseInt(clicked.value.split('-')[0]);
							const vehicleDetail = vehicleDetails.get(id);

							if (!clicked.isLeaf) {
								if (expanded.includes(clicked.value)) {
									const exList = expanded.filter((node) => node !== clicked.value);
									setExpanded(exList);
								} else {
									setExpanded([...expanded, clicked.value]);
								}
							} else {
								if (vehicleDetail?.data?.status !== 'Breakdown' && vehicleDetail?.data?.status !== 'Paused') {
									setChecked([clicked.value]);
									setSelectedVehicles([clicked.value]);
								}
							}
						}}
					/>
					<ResizableHandle />
				</SidebarContainer>
			)}
		</>
	);
}

export default SideBarNew2;
