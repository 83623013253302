import React, { useEffect, useRef, useState } from 'react';
import { getBounds } from 'geolib';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Checkbox, Dropdown, Icon, Label, Loader, Popup, Transition } from 'semantic-ui-react';
import { Circle, GoogleMap, InfoWindow, Marker, Polyline, TrafficLayer, useJsApiLoader } from '@react-google-maps/api';
import { GeolibBounds } from 'geolib/es/types';

import CustomInfoWindow from '../../../../../components/vehicles/info-window/CustomInfoWindow';
import { THEME } from '../../../../../constants/Theme';
import { createTrackDTO, getDistance, PolylineForTrack, TrackDTO, TrackEvent } from '../../../../../dtos/track';
import { createGeoFenceDTO, GeoFenceDTOData } from '../../../../../dtos/geofence';
import StartMarkerImage from '../../../../../assets/images/start-point-image.png';
import EndMarkerImage from '../../../../../assets/images/end-point-image.png';
import HybridImage from '../../../../../assets/images/hybrid-image.png';
import RoadmapImage from '../../../../../assets/images/roadmap-image.png';
import TrafficImage from '../../../../../assets/images/traffic-image.png';
import { fetchGeoFences } from '../../../geoFences/GeoFencesMiddleware';
import { formatVehicleEvent, getVehicleIcon, setMapType, setTrafficView } from '../../../../../utils/common';
import {
	ActiveMapTypes,
	AnimationControls,
	Container,
	ControlsContainer,
	HorizontalLinePathTypeInfo,
	MapTypeControls,
	MapTypesContainer,
	MapTypesLabels,
	PathType,
	PathTypeInfoBlock,
	PathTypeInfoContainer,
	ShouldAdjustMap,
	SummaryBlock,
	SummaryContainer,
	SummaryLabel,
	SummaryTimeBlock,
} from './TrackStyles';
import { Placeholder } from '../location/LocationStyles';
import { dispatch, dispatchNoPayload } from '../../../../../helper/view specific/store';
import { isoToHumanReadable } from '../../../../../utils/calendar';
import { MarkerInfo } from '../../../../../components/STYLED/InfoWindow';
import {
	GOOGLE_MAP_DEFAULT_CENTER,
	GOOGLE_MAP_DEFAULT_STYLES,
	GOOGLE_MAP_DEFAULT_ZOOM,
	GOOGLE_MAP_LIBRARIES,
	GOOGLE_MAP_OPTIONS,
	GOOGLE_MAPS_API_KEY,
} from '../../../../../constants/google';
import { TRACK_SPEED_DROPDOWN } from '../../../../../constants/constants';
import { trackMixpanelEvent } from '../../../../../helper/services/mixpanel';
import { handleError } from '../../../../../helper/view specific/messaging';
import { getData, postData } from '../../../../../helper/services/axios';
import { GEOCODE_API, TRACK_API } from '../../../../../constants/api';
import TrackNerdLoader from '../../../../../components/loader/Loader';
import Store from '../../../../../redux/store';
import { updateVehicleStatus } from '../../../../../helper/view specific/vehicleStatus';
import { Status } from '../../../../../components/STYLED/VehicleListStyles';
import { RootState } from '../../../../../redux/RootState';
import { Vehicle } from '../../../../../types/vehicle';

interface Coordinates {
	lat: number;
	lng: number;
}

const pixelOffset: google.maps.Size = {
	width: 0,
	height: -24,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};

const scaledSize: google.maps.Size = {
	width: 32,
	height: 32,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};

const anchor: google.maps.Point = {
	x: 16,
	y: 16,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};
const Track = () => {
	const vehicleState = useSelector((state: RootState) => state.vehicleSlice.entities);
	const sideBarState = useSelector((state: RootState) => state.sidebar);
	const vehicleDetails = useSelector((state: RootState) => state.vehicle.vehicleDetails);
	const dateState = useSelector((state: RootState) => state.dates);
	const authState = useSelector((state: RootState) => state.auth);
	const locationState = useSelector((state: RootState) => state.location);
	const eventLocation = useSelector((state: RootState) => state.vehicle.eventData);
	const trackDataStore = useSelector((state: RootState) => state.reduceApi.trackDataStore);

	const [vehicleId, setVehicleID] = useState<number | null>(null);
	const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
	const [boundsArray, setBoundsArray] = useState<TrackDTO[]>([]);
	const [showInfoWindow, setShowInfoWindow] = useState(false);
	const [infoWindowPosition, setInfoWindowPosition] = useState<Coordinates | null>(null);
	const [infoWindowContent, setInfoWindowContent] = useState<TrackDTO | null>(null);
	const [openLastPointInfo, setOpenLastPointInfo] = useState(false);
	const [eventsInfoWindow, setEventsInfoWindow] = useState(false);
	const [googleAddress, setGoogleAddress] = useState('Fetching...');
	const [distanceFromLastAddress, setDistanceFromLastAddress] = useState<number | null>(null);
	const [latestDistance, setLatestDistance] = useState<number>(-1);
	const [loading, setLoading] = useState(false);
	const [geoFences, setGeoFences] = useState<GeoFenceDTOData[] | null>(null);
	const [shouldAdjustMap, setShouldAdjustMap] = useState(false);
	const [paths, setPaths] = useState<TrackDTO[]>([]);
	const [animationPath, setAnimationPath] = useState<TrackDTO | null>(null);
	const [animationIndex, setAnimationIndex] = useState(0);
	const [polylines, setPolylines] = useState<PolylineForTrack[] | null>(null);
	const [eventData, setEventData] = useState<TrackEvent[]>([]);
	const [selectedEventData, setSelectedEventData] = useState<TrackEvent[] | null>(null);
	const [openFieldsMenu, setOpenFieldsMenu] = useState(false);
	const [eventName, setEventName] = useState<string[]>([]);
	const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
	const [eventInfoContent, setEventInfoContent] = useState<TrackEvent | null>(null);
	const [show7DaysPlaceholder, setShow7DaysPlaceholder] = useState(false);
	const [iconRepeat, setIconRepeat] = useState<string | ''>('');
	const [showPolylineTypeInfo, setShowPolylineTypeInfo] = useState<boolean>(false);

	// share tracking playback
	/*const [trackingLink, setTrackingLink] = useState<string>('');
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [dropdownVehicles, setDropdownVehicles] = useState([]);
    const [selectedEmailPhone, setSelectedEmailPhone] = useState([]);
    const [EmailsPhonesOptions, setEmailsPhonesOptions] = useState([]);
    const [quantityOfTime, setQuantityOfTime] = useState(1);
    const [unitOfTime, setUnitOfTime] = useState('days');
    */

	const showAnimationInfoWindow = useRef(false);
	const animationOn = useRef(false);
	const trackSpeed = useRef<number>(1);
	const firstRenderRef = useRef(true);

	let bounds: GeolibBounds;
	const columnOptions = ['Ignition', 'Relay', 'Geofence', 'Main Power', 'Idling', 'OverSpeed', 'SOS', 'Parking', 'Ac'];
	const vehicleLocation = vehicleId ? vehicleState[vehicleId]?.location : null;
	const vehicleStatus = vehicleLocation && updateVehicleStatus(vehicleLocation);

	useEffect(() => {
		const str = localStorage.getItem('eventSelected');
		if (str) {
			setEventName(JSON.parse(str));
			filterEventData(eventData, JSON.parse(str));
		}
		dispatch('SET_SIDEBAR_CONFIG', {
			showCalendar: true,
			multiSelectAllowed: true,
		});
		Store.dispatch({
			type: 'DISABLE_DATEPICKER',
			payload: false,
		});
		Store.dispatch({
			type: 'DISABLE_TIMEPICKER',
			payload: false,
		});
		Store.dispatch({
			type: 'TRACK_7_DAYS',
			payload: true,
		});
		Store.dispatch({
			type: 'SELECTED_TAB',
			payload: 'Track',
		});

		if (trackDataStore.length) {
			if (sideBarState.selectedVehicles.length >= 1) {
				fetchDataRedux();
				setVehicleID(sideBarState.selectedVehicles[0]);
			} else {
				setLatestDistance(-1);
				setPaths([]);
			}
		}
		return () => {
			dispatchNoPayload('RESTORE_SIDEBAR_CONFIG');
			Store.dispatch({
				type: 'TRACK_7_DAYS',
				payload: false,
			});
			Store.dispatch({
				type: 'SELECTED_TAB',
				payload: 'Live',
			});
		};
	}, []);

	useEffect(() => {
		const difference = moment.duration(moment(dateState.finalDate).diff(moment(dateState.initialDate))).asDays();
		if (difference < 7) {
			if (!firstRenderRef.current) {
				if (sideBarState.selectedVehicles.length === 1) {
					fetchData(sideBarState.selectedVehicles);
					setVehicleID(sideBarState.selectedVehicles[0]);
				} else {
					setLatestDistance(-1);
					setPaths([]);
				}
			} else {
				if (sideBarState.selectedVehicles.length === 1) {
					if (!trackDataStore.length) {
						fetchData(sideBarState.selectedVehicles);
						setVehicleID(sideBarState.selectedVehicles[0]);
					}
				} else {
					setLatestDistance(-1);
					setPaths([]);
				}

				firstRenderRef.current = false;
			}
		} else {
			setShow7DaysPlaceholder(true);
		}
		setShowInfoWindow(false);
		setDistanceFromLastAddress(null);
		setGoogleAddress('Fetching...');

		// Reset Playback State
		setAnimationIndex(0);
		setAnimationPath(paths[0]);
		animationOn.current = false;
		showAnimationInfoWindow.current = false;
		fetchGeoFence();
	}, [sideBarState.selectedVehicles, dateState.initialDate, dateState.finalDate, eventName]);

	useEffect(() => {
		if (boundsArray.length && mapInstance) setBounds(mapInstance);
	}, [boundsArray]);

	/*	useEffect(() => {
            try {
                if (paths && paths.length !== 0 && !loading && moment().isSame(moment(dateState.finalDate), 'day')) {
                    updatePath();
                }
            } catch (error) {
                // datadogLogs.logger.error('Track UseEffect 3 Error', {}, error);
            }
        }, [vehicleState]);*/

	// Function to filter data objects with non-empty 'events' array
	function filterDataWithEvents(dataArray: TrackDTO[]) {
		return dataArray.filter(
			(dataObj) =>
				(dataObj.startEvents && dataObj.startEvents.length > 0) || (dataObj.endEvents && dataObj.endEvents.length > 0)
		);
	}

	const fetchGeoFence = () => {
		fetchGeoFences('?pagecount=1000')
			.then((response) => {
				setGeoFences(createGeoFenceDTO(response?.data?.data));
			})
			.catch((error) => {
				handleError('Track.tsx => fetchGeofence()', error);
			});
	};

	const fetchData = (vehicles: number[]) => {
		setLoading(true);
		const vehiclesParams = vehicles.map((vehicle: number) => vehicle);
		const startTime = dateState.initialDate;
		const endTime = dateState.finalDate;
		const requestBody = {
			vehicleIds: vehiclesParams,
			startTime: startTime,
			endTime: endTime,
		};
		postData(TRACK_API, '', requestBody)
			.then((response) => {
				if (response?.data.length) {
					let path: TrackDTO[] = [];
					let distance: number = 0;
					let boundsArray: TrackDTO[] = [];
					dispatch('TRACK_DATA_STORE', response.data);
					response.data.forEach((vehicleData: Vehicle) => {
						if (vehicleData.iotData.length) {
							const dtoResponse = createTrackDTO(vehicleData);

							if (dtoResponse !== undefined) {
								const [allCoordinates, allPolylines, allAllowedEvents] = dtoResponse;

								setEventData(allAllowedEvents);
								if (allAllowedEvents.length) filterEventData(allAllowedEvents, eventName);

								// Filter the data with events
								const dataWithEvents = filterDataWithEvents(allCoordinates);

								// Find the data object that contains the specific event ID
								const dataWithSpecificEvent = dataWithEvents.find(
									(dataObj) =>
										dataObj.startEvents &&
										eventLocation &&
										dataObj.startEvents.some((event): boolean => event.id === eventLocation.id) &&
										dataObj.endEvents &&
										eventLocation &&
										dataObj.endEvents.some((event): boolean => event.id === eventLocation.id)
								);

								if (dataWithSpecificEvent) {
									setAnimationPath(dataWithSpecificEvent);
									setAnimationIndex(dataWithSpecificEvent.sliderIndex);
								}

								//if we route from events to track
								if (eventLocation && Object.keys(eventLocation).length) {
									allAllowedEvents.map((event: TrackEvent) => {
										if (eventLocation.id === event.id) {
											setEventName([event.type]);
											filterEventData([event], event.type);
											setInfoWindowPosition({
												lat: event.lat,
												lng: event.lat,
											});
											setEventInfoContent(event);
											setEventsInfoWindow(true);
										}
									});
								}

								if (allCoordinates?.length) {
									path = allCoordinates;
									boundsArray = [...boundsArray, ...allCoordinates];
									distance = allCoordinates.length ? allCoordinates[allCoordinates.length - 1].distance : 0;
								}
								setPolylines(allPolylines);
							}
						}
					});

					setPaths(path);
					setBoundsArray(boundsArray);
					setLatestDistance(distance);
					setLoading(false);
					setDistanceFromLastAddress(null);
					setGoogleAddress('Fetching...');
					if (path?.[0]) setAnimationPath(path[0]);
				} else {
					setPaths([]);
					setBoundsArray([]);
					setLatestDistance(-1);
					setLoading(false);
					setDistanceFromLastAddress(null);
					setGoogleAddress('Fetching...');
				}
			})
			.catch((error) => {
				handleError('Track.tsx => fetchData()', error);
				setLoading(false);
			});
		trackMixpanelEvent('track_report', {
			vehicles: sideBarState.selectedVehiclesNumbers,
			startTime: startTime,
			endTime: endTime,
		});
	};

	const fetchDataRedux = () => {
		let path: TrackDTO[] = [];
		let distance: number = -1;
		let boundsArray: TrackDTO[] = [];

		trackDataStore.forEach((vehicleData: Vehicle) => {
			if (vehicleData.iotData.length) {
				const dtoResponse = createTrackDTO(vehicleData);

				if (dtoResponse !== undefined) {
					const [allCoordinates, allPolylines, allAllowedEvents] = dtoResponse;

					setEventData(allAllowedEvents);
					if (allAllowedEvents.length) filterEventData(allAllowedEvents, eventName);

					if (allCoordinates?.length) {
						path = allCoordinates;
						boundsArray = [...boundsArray, ...allCoordinates];
						distance = allCoordinates.length ? allCoordinates[allCoordinates.length - 1].distance : 0;
						setPaths(allCoordinates);
						setBoundsArray(boundsArray);
						setLatestDistance(distance);
						setLoading(false);
					}
					setPolylines(allPolylines);
				}
			}
		});

		setDistanceFromLastAddress(null);
		setGoogleAddress('Fetching...');
		if (path?.[0]) setAnimationPath(path[0]);
	};

	const setBounds = (map: google.maps.Map) => {
		if (boundsArray?.length && mapInstance) {
			bounds = getBounds(boundsArray);
			map.fitBounds({
				north: bounds.maxLat,
				south: bounds.minLat,
				east: bounds.maxLng,
				west: bounds.minLng,
			});
		}
	};

	const getClosestCoordinate = (lat: number, lng: number) => {
		let distance = null;
		let closestDistance = 99999999;
		let closestPointIndex = null;

		for (let i = 0; i < paths.length - 1; i++) {
			distance = getDistance(paths[i].lat, paths[i].lng, lat, lng);

			if (distance < closestDistance) {
				closestDistance = distance;
				closestPointIndex = i;
			}
		}
		if (closestPointIndex !== null) {
			setInfoWindowPosition({
				lat: paths[closestPointIndex].lat,
				lng: paths[closestPointIndex].lng,
			});
			setInfoWindowContent(paths[closestPointIndex]);
			setShowInfoWindow(true);
			setOpenLastPointInfo(false);

			setAddress(paths[closestPointIndex].lat, paths[closestPointIndex].lng);
		}
	};

	const setMapTypeLocal = (value: string) => {
		if (value !== locationState.mapType) {
			setMapType(value);
		}
	};

	const setAddress = (lat: number, lng: number) => {
		getData(GEOCODE_API, `?latitude=${lat}&longitude=${lng}`)
			.then((response) => {
				if (response?.data?.address) {
					setGoogleAddress(response.data.address);
					setDistanceFromLastAddress(null);
				}
			})
			.catch((error) => {
				handleError('Track.tsx => setAddress()', error);
			});
	};

	const refreshAddress = () => {
		setAddress(paths[paths.length - 1].lat, paths[paths.length - 1].lng);
	};

	/*const isRequireLocationUpdate = (id: number) => {
        const location = vehicleState[id]?.location;

        if (location) {
            return (
                moment(location?.timestamp).isBefore(moment(dateState.finalDate)) &&
                moment(location?.timestamp).isAfter(moment(dateState.initialDate))
            );
        } else {
            return null;
        }
    };

        const updatePath = () => {
            try {
                if (sideBarState.selectedVehicles.length) {
                    sideBarState.selectedVehicles.forEach((vehicle) => {
                        const location = vehicleState[vehicle]?.location;
                        if (
                            isRequireLocationUpdate(vehicle) &&
                            paths?.length &&
                            location?.latitude !== paths[paths?.length - 1]?.lat &&
                            location?.longitude !== paths[paths?.length - 1]?.lng
                        ) {
                            // setNewLiveDataPoint(vehicle, vehicleState[vehicle]?.location);
                        }
                    });
                }
            } catch (error) {
                // handleError(error);
                // datadogLogs.logger.error('Track updatePath Error', {}, error);
            }
        };*/

	const getPopupInfo = () => (
		<MapTypesContainer>
			<MapTypeControls activeType={locationState.traffic} onClick={() => setTrafficView(!locationState.traffic)}>
				<img src={TrafficImage} alt="Traffic" />
			</MapTypeControls>
			<MapTypesLabels activeType={locationState.traffic}>Traffic</MapTypesLabels>
		</MapTypesContainer>
	);

	// Get Vehicle Status
	// const getCurrentVehicleStates = (status) => {
	//   return status === VEHICLE_STATES.Breakdown
	//     ? "Breakdown"
	//     : status === VEHICLE_STATES.Maintenance
	//       ? "Maintenance"
	//       : status === VEHICLE_STATES["Stand By"]
	//         ? "Stand By"
	//         : "";
	// };

	const animationTurnedOn = async (index: number) => {
		const iotLength = paths.length;
		let delay: number;

		trackMixpanelEvent('track_play_pause', {
			vehicles: sideBarState.selectedVehiclesNumbers,
			playback: 'play',
		});

		for (let i = index; i < iotLength; i++) {
			if (!animationOn.current) {
				return;
			}
			/*if (i > 0 && paths[i - 1].events.length) {
                delay = 1000 / trackSpeed.current;
            } else delay = 30 / trackSpeed.current;*/

			const path = paths[i - 1];
			if (i > 0 && (path?.startEvents?.length || path?.endEvents?.length)) {
				delay = 1000 / trackSpeed.current;
			} else delay = 30 / trackSpeed.current;
			await new Promise((resolve) =>
				setTimeout(() => {
					if (animationOn.current) {
						showAnimationInfoWindow.current = !!(paths[i]?.startEvents?.length || paths[i]?.endEvents?.length);

						setAnimationPath(paths[i]);
						setAnimationIndex((prevState) => prevState + 1);
					}
					resolve('resolved');
				}, delay)
			);
		}
		setAnimationIndex(0);
		animationOn.current = false;
		setAnimationPath(paths[0]);
		showAnimationInfoWindow.current = false;
	};

	const filterEventData = (events: TrackEvent[], selectedEvents: string | string[]) => {
		const selectEvent = [];
		for (let i = 0; i < events.length; i++) {
			if (selectedEvents.includes(events[i].type)) selectEvent.push(events[i]);
		}
		setSelectedEventData(selectEvent);
	};

	// share tracking playback
	/*const shareTrackingLink = () => {
        // logSelectContentEvent('click', 'Share Tracking Link', 'shareTrackingLink');

        setTrackingLink('');

        let query = {
            vehicles: selectedVehicles.map((vehicle) => {
                return { id: Number(vehicle) };
            }),
        };
        if (selectedEmailPhone.length) {
            const phones = [];
            const emails = [];
            selectedEmailPhone.forEach((value) => {
                if (IsMobileNumberValid(value)) {
                    phones.push(value);
                } else if (IsEmailValid(value)) {
                    emails.push(value);
                }
            });

            if (phones.length) {
                query = {
                    ...query,
                    phone: phones,
                };
            }
            if (emails.length) {
                query = {
                    ...query,
                    email: emails,
                };
            }
        }

        query = {
            ...query,
            expiry: moment().add(quantityOfTime, unitOfTime).toISOString(),
        };
        query = {
            ...query,
            type: 'Track',
            trackStartTime: moment(datePart1, 'DD-MM-YYYY').startOf('day').toISOString(),
            trackEndTime: moment(datePart2, 'DD-MM-YYYY').endOf('day').toISOString(),
        };

        shareTrackingLinkRequest(query)
            .then((response) => {
                handleSuccess('Success');
                setTrackingLink(response.data);
                cleanShareTrackingLinkOperation();
                //closeTrackingLinkModal();
                openTrackingLinkViewModal();
                Store.dispatch({
                    type: 'BUTTON_LOADED',
                });
            })
            .catch((error) => {
                datadogLogs.logger.error('Track Share Tracking Link Api Error', {}, error);
                handleError(error);
                Store.dispatch({
                    type: 'BUTTON_LOADED',
                });
            });
    };
    const handleChange = (event, { name, value }) => {
        try {
            if (name === 'date1') {
                setDatePart1(value);
                const selectedDate = moment(value, 'DD-MM-YY');
                let maxDate = moment(selectedDate).add(6, 'days').format('DD-MM-YY');
                const today = moment().startOf('day');

                if (selectedDate.isAfter(today.clone().subtract(6, 'days')) && selectedDate.isSameOrBefore(today)) {
                    maxDate = today.format('DD-MM-YY');
                }
                setDatePart3(maxDate);
            }

            if (name === 'date2') {
                setDatePart2(value);
            }
        } catch (error) {
            datadogLogs.logger.error('Track  Calendar handleChange Error', {}, error);
        }
    };
    const cleanShareTrackingLinkOperation = () => {
        setSearchQuery();
        setSelectedEmailPhone([]);
        setEmailsPhonesOptions([]);
        setQuantityOfTime(1);
        setUnitOfTime('days');
        Store.dispatch({ type: 'RESET_SELECTED_DATE' });
    };
    const getVehiclesforDropdown = () => {
        getData(VEHICLES_API, '?dropdown=true')
            .then((res) => {
                setDropdownVehicles(
                    res.data.data.map((vehicle, index) => {
                        return { key: index, value: vehicle.id, text: vehicle.registrationNumber };
                    })
                );
                setSelectedVehicles([sideBarState.selectedVehicles[0]]);
            })
            .catch((error) => {
                handleError(error);
                datadogLogs.logger.error('Vehicle Api Error', {}, error);
            });
    };
*/

	const getCurrentZoomLevel = () => {
		if (mapInstance) {
			const zoom: number | undefined = mapInstance.getZoom();
			if (zoom !== undefined) {
				if (zoom > 5 && zoom <= 13) {
					setIconRepeat('');
				} else if (zoom >= 14 && zoom < 17) {
					setIconRepeat('600px');
				} else if (zoom >= 17 && zoom <= 20) {
					setIconRepeat('400px');
				}
			}
		}
	};

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: GOOGLE_MAP_LIBRARIES,
	});

	const toggleSelection = (checked: boolean | undefined, name: string) => {
		let selectedRowTemp = selectedColumns;

		if (checked) {
			selectedRowTemp.push(name);
		} else {
			selectedRowTemp = selectedRowTemp.filter((columnName) => columnName !== name);
		}
		setSelectedColumns([...selectedRowTemp]);
	};

	const EventInfoWindow = () => {
		const startEvents = paths[animationIndex]?.startEvents;
		const endEvents = paths[animationIndex]?.endEvents;

		const animationPathEvent =
			(animationPath?.startEvents && animationPath?.startEvents[0]) ||
			(animationPath?.endEvents && animationPath?.endEvents[0]) ||
			null;

		if (
			((startEvents && startEvents.length) || (endEvents && endEvents.length)) &&
			showAnimationInfoWindow.current &&
			animationPath &&
			animationPathEvent
		) {
			return (
				<InfoWindow
					position={{
						lat: animationPath.lat,
						lng: animationPath.lng,
					}}
					options={{
						pixelOffset: pixelOffset,
					}}>
					<div
						style={{
							width: 'fit-content',
							height: 'fit-content',
							display: 'flex',
							flexDirection: 'column',
						}}>
						<div>{formatVehicleEvent(animationPathEvent)}</div>
						<div>{isoToHumanReadable(animationPath.timestamp)}</div>
					</div>
				</InfoWindow>
			);
		}
	};

	const SummaryTime = () => {
		if (vehicleId) {
			const time = vehicleState[vehicleId]?.location.timestamp;
			if (openLastPointInfo && time) {
				return (
					<SummaryBlock>
						<span>{isoToHumanReadable(time)}</span>
					</SummaryBlock>
				);
			} else {
				return (
					<SummaryBlock>
						<span>{isoToHumanReadable(paths[animationIndex]?.timestamp)}</span>
					</SummaryBlock>
				);
			}
		}
	};
	const markerSelected = (index: number) => {
		setVehicleID(paths[0].id);
		setInfoWindowPosition({
			lat: paths[index].lat,
			lng: paths[index].lng,
		});
		setInfoWindowContent(paths[index]);
		setShowInfoWindow(true);
		setOpenLastPointInfo(false);
		setAddress(paths[index].lat, paths[index].lng);
	};

	if (loadError) {
		return <div>Error loading Google Maps API</div>;
	}
	if (isLoaded) {
		const vehicleType = paths && paths.length ? vehicleDetails.get(paths[0].id)?.data?.type : null;

		return (
			<Container>
				<GoogleMap
					mapContainerStyle={GOOGLE_MAP_DEFAULT_STYLES}
					center={GOOGLE_MAP_DEFAULT_CENTER}
					zoom={GOOGLE_MAP_DEFAULT_ZOOM}
					onDrag={() => {
						if (!shouldAdjustMap) {
							setShouldAdjustMap(!shouldAdjustMap);
						}
					}}
					onZoomChanged={() => {
						getCurrentZoomLevel();
						if (!shouldAdjustMap) {
							setShouldAdjustMap(!shouldAdjustMap);
						}
					}}
					options={{
						...GOOGLE_MAP_OPTIONS,
						fullscreenControl: false,
						mapTypeId: locationState.mapType,
					}}
					onLoad={(map) => {
						setMapInstance(map);
						setBounds(map);
					}}
					onUnmount={() => setMapInstance(null)}
					onClick={() => {
						setEventsInfoWindow(false);
						setShowInfoWindow(false);
					}}>
					{shouldAdjustMap && (
						<ShouldAdjustMap>
							<Button.Group>
								<Button
									style={{ marginRight: '.5em' }}
									icon="filter"
									disabled={!sideBarState.selectedVehicles.length}
									onClick={() => {
										if (!openFieldsMenu) setOpenFieldsMenu(true);
										else setOpenFieldsMenu(false);
										setSelectedColumns([...eventName]);
										animationOn.current = false;
									}}
								/>
								{openFieldsMenu && (
									<Dropdown
										open={openFieldsMenu}
										pointing={'top right'}
										multiple
										style={{ width: 'fit-content' }}
										text="Select Column Fields"
										floating
										labeled>
										<Dropdown.Menu>
											<Dropdown.Header style={{ textAlign: 'center' }}>
												<h3>Select Events</h3>
											</Dropdown.Header>
											{columnOptions.map((column, index) => (
												<Dropdown.Item key={index}>
													<Checkbox
														checked={selectedColumns.includes(column)}
														label={column}
														onClick={(e, { checked }) => {
															toggleSelection(checked, column);
														}}
													/>
												</Dropdown.Item>
											))}
											<Dropdown.Item>
												<Button color="black" style={{ marginRight: '10px' }} onClick={() => setOpenFieldsMenu(false)}>
													Cancel
												</Button>
												<Button
													primary
													onClick={() => {
														setEventName(selectedColumns);
														const jsonArray = JSON.stringify(selectedColumns);
														localStorage.setItem('eventSelected', jsonArray);
														setOpenFieldsMenu(false);
														filterEventData(eventData, selectedColumns);
													}}>
													Apply
												</Button>
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								)}
							</Button.Group>
							<Button
								icon="crosshairs"
								style={{ marginTop: '1em', marginRight: '.5em' }}
								onClick={() => {
									if (mapInstance) setBounds(mapInstance);
								}}
							/>

							<div
								style={{ marginTop: '1em', marginRight: '.5em' }}
								onMouseOut={() => {
									setShowPolylineTypeInfo(false);
								}}
								onMouseOver={() => {
									setShowPolylineTypeInfo(true);
								}}>
								<Button
									icon="info circle"
									disabled={!sideBarState.selectedVehicles.length}
									onClick={() => {
										if (!showPolylineTypeInfo) setShowPolylineTypeInfo(true);
										else setShowPolylineTypeInfo(false);
									}}
								/>
							</div>
						</ShouldAdjustMap>
					)}
					{showPolylineTypeInfo && (
						<PathTypeInfoContainer>
							<PathTypeInfoBlock>
								<HorizontalLinePathTypeInfo />
								<PathType>Normal</PathType>
							</PathTypeInfoBlock>
							<PathTypeInfoBlock>
								<HorizontalLinePathTypeInfo color={'red'} />
								<PathType>Overspeed</PathType>
							</PathTypeInfoBlock>
							<PathTypeInfoBlock>
								<HorizontalLinePathTypeInfo color={'orange'} />
								<PathType>No Ignition Data</PathType>
							</PathTypeInfoBlock>
							<PathTypeInfoBlock>
								<div
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
										display: 'flex',
										flexDirection: 'row',
										flex: 1,
									}}>
									<HorizontalLinePathTypeInfo color={'black'} width={'16px'} />
									<HorizontalLinePathTypeInfo color={'black'} width={'16px'} />
									<HorizontalLinePathTypeInfo color={'black'} width={'16px'} />
									<HorizontalLinePathTypeInfo color={'black'} width={'16px'} />
								</div>
								<PathType>Jump</PathType>
							</PathTypeInfoBlock>
						</PathTypeInfoContainer>
					)}
					{locationState.traffic && <TrafficLayer />}
					<>
						{sideBarState.selectedVehicles.length === 1 &&
							polylines?.length &&
							paths &&
							paths.length &&
							animationPath && (
								<Marker
									position={{
										lat: animationPath.lat,
										lng: animationPath.lng,
									}}
									zIndex={1}
									options={{
										icon: {
											url: StartMarkerImage,
											scaledSize: scaledSize,
											anchor: anchor,
										},
									}}
									onClick={() => {
										markerSelected(animationIndex);
									}}
								/>
							)}

						{/*Vehicle Marker*/}
						{vehicleType && sideBarState.selectedVehicles?.length === 1 && paths?.length && (
							<Marker
								position={{
									lat: animationPath?.lat || paths[0].lat,
									lng: animationPath?.lng || paths[0].lng,
								}}
								zIndex={2}
								icon={{
									url: getVehicleIcon(vehicleType || ''),
									scaledSize: {
										width: 42,
										height: 42,
										equals: function (): boolean {
											throw new Error('Function not implemented.');
										},
									},
									anchor: {
										x: 21,
										y: 21,
										equals: function (): boolean {
											throw new Error('Function not implemented.');
										},
									},
								}}
								onClick={() => {
									markerSelected(animationIndex);
								}}
							/>
						)}

						{/*End Marker*/}
						{sideBarState.selectedVehicles.length === 1 && polylines?.length && paths?.length && (
							<Marker
								position={{
									lat: paths[paths.length - 1].lat,
									lng: paths[paths.length - 1].lng,
								}}
								zIndex={1}
								options={{
									icon: {
										url: EndMarkerImage,
										scaledSize: {
											width: 32,
											height: 32,
											equals: function (): boolean {
												throw new Error('Function not implemented.');
											},
										},
										anchor: {
											x: 16,
											y: 16,
											equals: function (): boolean {
												throw new Error('Function not implemented.');
											},
										},
									},
								}}
								onClick={() => {
									markerSelected(paths.length - 1);
								}}
							/>
						)}

						{vehicleId &&
							polylines &&
							paths.length &&
							polylines.map((polyline, index) => {
								const shouldShowArrow = polyline.coordinates.length < 20 ? iconRepeat === '400px' : true;
								return (
									<Polyline
										key={index}
										path={polyline.coordinates}
										options={{
											strokeColor:
												polyline.typeOfPolyline === 'jump'
													? 'black'
													: polyline.typeOfPolyline === 'overspeed'
														? 'red'
														: polyline.typeOfPolyline === 'looseWiring'
															? 'orange'
															: THEME.COLORS.multiplePolyLinesTrack?.[0], // Color for regular polyline
											strokeOpacity: 1,
											strokeWeight: 5,
											icons:
												polyline.typeOfPolyline === 'jump'
													? [
															{
																icon: {
																	path: 'M 0,-1 0,1',
																	strokeOpacity: 100, // Set strokeOpacity to 0 for transparent dotted part
																	scale: 4,
																},
																offset: '0',
																repeat: '20px', // Adjust the 'px' value to control the length of the dotted segments
															},
														]
													: shouldShowArrow
														? [
																{
																	icon: {
																		path: 'M -2,2 0,-4 2,2 0,0 z',
																		strokeColor: THEME.COLORS.polylineMarkerStroke,
																		strokeWeight: 2,
																		fillColor:
																			polyline.typeOfPolyline === 'overspeed'
																				? 'red'
																				: polyline.typeOfPolyline === 'looseWiring'
																					? 'orange'
																					: THEME.COLORS.multiplePolyLinesTrack[0],
																		fillOpacity: 1,
																	},
																	offset: '10%',
																	repeat: iconRepeat,
																},
															]
														: [],
										}}
										onClick={(e) => {
											if (polyline.typeOfPolyline !== 'jump' && e.latLng !== null)
												getClosestCoordinate(e.latLng.lat(), e.latLng.lng());
											else {
												getClosestCoordinate(polyline.coordinates[0].lat, polyline.coordinates[0].lng);
											}
										}}
									/>
								);
							})}

						{showInfoWindow && vehicleId && paths.length && infoWindowPosition !== null && (
							<InfoWindow
								position={{
									lat: openLastPointInfo ? paths[paths.length - 1].lat : infoWindowPosition.lat,
									lng: openLastPointInfo ? paths[paths.length - 1].lng : infoWindowPosition.lng,
								}}
								options={{
									pixelOffset: pixelOffset,
								}}>
								<CustomInfoWindow
									id={vehicleId}
									coordinates={{
										latitude: infoWindowPosition.lat,
										longitude: infoWindowPosition.lng,
									}}
									content={infoWindowContent}
									liveStatus={
										moment(vehicleState[vehicleId]?.location.timestamp).isBefore(moment(dateState.finalDate)) &&
										openLastPointInfo
									}
									VehicleState={
										moment(vehicleState[vehicleId]?.location.timestamp).isBefore(moment(dateState.finalDate)) &&
										openLastPointInfo
									}
									shareTrackingLink={false}
									immobilizer={false}
									recalculation={false}
									relayIconShow={false}
									liveAddress={openLastPointInfo}
									distanceFromLastAddress={distanceFromLastAddress}
									googleAddress={googleAddress}
									onRefreshAddress={refreshAddress}
									liveOdometer={openLastPointInfo}
									liveSpeed={openLastPointInfo}
									liveDistance={openLastPointInfo}
									liveDistanceData={latestDistance}
									liveBattery={openLastPointInfo}
									liveSOC={openLastPointInfo}
									liveDTE={openLastPointInfo}
									liveFuel={
										moment(vehicleState[vehicleId]?.location.timestamp).isBefore(moment(dateState.finalDate)) &&
										openLastPointInfo
									}
								/>
							</InfoWindow>
						)}

						<EventInfoWindow />
					</>
					{sideBarState.selectedVehicles.length === 1 &&
						polylines?.length &&
						selectedEventData &&
						selectedEventData.map((event, index) => {
							return (
								<Marker
									position={{
										lat: event.lat,
										lng: event.lng,
									}}
									zIndex={1}
									key={index}
									onClick={() => {
										setInfoWindowPosition({
											lat: event.lat,
											lng: event.lat,
										});
										setEventInfoContent(event);
										setEventsInfoWindow(true);
									}}
								/>
							);
						})}
					{sideBarState.selectedVehicles.length === 1 && eventsInfoWindow && eventInfoContent !== null && (
						<InfoWindow
							// key={animationIndex}
							position={{
								lat: eventInfoContent.lat,
								lng: eventInfoContent.lng,
							}}
							options={{
								pixelOffset: pixelOffset,
							}}>
							<div
								style={{
									width: 'fit-content',
									height: 'fit-content',
									display: 'flex',
									flexDirection: 'column',
								}}>
								<div>{formatVehicleEvent(eventInfoContent)}</div>
								<div>{eventInfoContent.startAddress ? eventInfoContent.startAddress : eventInfoContent.endAddress}</div>
								<div>{eventInfoContent.timestamp}</div>
							</div>
						</InfoWindow>
					)}

					{authState.shownGeoFences &&
						geoFences &&
						geoFences.map((geofence, index) => {
							return (
								<Circle
									key={index}
									center={{
										lat: geofence.latitude,
										lng: geofence.longitude,
									}}
									radius={geofence.radius}
									options={{
										strokeColor: 'dodgerblue',
										strokeWeight: 2,
										fillColor: 'dodgerblue',
										fillOpacity: 0.1,
									}}
								/>
							);
						})}

					<ControlsContainer>
						<Popup
							content={getPopupInfo()}
							position="right center"
							offset={[0, 5]}
							basic
							on="hover"
							hoverable
							style={{
								width: '5em',
								height: '5em',
								padding: '0',
								paddingTop: '6px',
								borderRadius: '8px',
							}}
							trigger={
								<ActiveMapTypes
									onClick={() => setMapTypeLocal(`${locationState.mapType === 'roadmap' ? 'hybrid' : 'roadmap'}`)}>
									{locationState.mapType === 'roadmap' ? (
										<img src={HybridImage} alt="Hybrid" />
									) : (
										<img src={RoadmapImage} alt="RoadMap" />
									)}
								</ActiveMapTypes>
							}
						/>
					</ControlsContainer>

					{!loading &&
						sideBarState.selectedVehicles &&
						sideBarState.selectedVehicles.length === 1 &&
						paths &&
						paths.length !== 0 && (
							<>
								<SummaryContainer>
									<MarkerInfo>
										<SummaryTimeBlock>
											<SummaryLabel>
												<Label size="mini" circular color="green" />
											</SummaryLabel>

											<span>Start Time : {isoToHumanReadable(paths[0]?.timestamp)}</span>
										</SummaryTimeBlock>
										<SummaryBlock>
											{latestDistance ? (
												<span>Distance : {Number((latestDistance / 1000).toFixed(2)).toLocaleString()} km</span>
											) : (
												<span>Distance : 0 km</span>
											)}
										</SummaryBlock>
										<SummaryTimeBlock>
											<SummaryLabel>
												<Label size="mini" circular color="red" />
											</SummaryLabel>
											<span>End Time : {isoToHumanReadable(paths[paths.length - 1]?.timestamp)}</span>
										</SummaryTimeBlock>
										{animationOn.current && (
											<>
												<hr />
												<SummaryBlock>
													<span>{sideBarState.selectedVehiclesNumbers}</span>
												</SummaryBlock>
												<SummaryBlock>
													<span>
														{vehicleId &&
														moment(vehicleState[vehicleId]?.location.timestamp).isBefore(moment(dateState.finalDate)) &&
														openLastPointInfo ? (
															<Status
																color={
																	vehicleStatus === 'GPS not fixed'
																		? THEME.COLORS.vehicleNoDataHalfHour
																		: vehicleStatus === 'Moving'
																			? THEME.COLORS.vehicleMoving
																			: vehicleStatus === 'Idle'
																				? THEME.COLORS.vehicleIdle
																				: vehicleStatus === 'Ignition off'
																					? THEME.COLORS.vehicleIgnitionOff
																					: THEME.COLORS.vehicleNoDataHalfHour
																}>
																{vehicleStatus}
															</Status>
														) : paths[animationIndex]?.ignitionStatus === false ? (
															<Status color={THEME.COLORS.vehicleIgnitionOff}>Ignition Off</Status>
														) : paths[animationIndex]?.speed === '0' ? (
															<Status color={THEME.COLORS.warning}>Idle</Status>
														) : (
															<Status color={THEME.COLORS.vehicleMoving}>Moving</Status>
														)}
													</span>
												</SummaryBlock>
												<SummaryBlock>
													{openLastPointInfo && latestDistance !== null ? (
														<span>Distance {(latestDistance / 1000).toFixed(2)} km</span>
													) : (
														<span>Distance {(paths[animationIndex]?.distance / 1000).toFixed(2)} km</span>
													)}
													{openLastPointInfo ? (
														vehicleState &&
														vehicleId &&
														Object.hasOwn(vehicleState, vehicleId) && (
															<span>Speed {Number(vehicleState?.[vehicleId]?.location?.speed).toFixed(2)} km/h</span>
														)
													) : (
														<span>Speed {Number(paths[animationIndex]?.speed).toFixed(2)} km/h</span>
													)}
												</SummaryBlock>
												<SummaryBlock>
													{<span>Battery {(paths[animationIndex]?.batteryLevel / 1000).toFixed(2)} Volts</span>}
													{/*{vehicleState &&
														Object.hasOwn(vehicleState, vehicleId) &&
														vehicleState[vehicleId].location?.externalPowerVoltage !== undefined &&
														(openLastPointInfo ? (
															<span>
																Battery {(vehicleState[vehicleId].location?.externalPowerVoltage / 1000).toFixed(2)}{' '}
																Volts
															</span>
														) : (
															<span>Battery {(paths[animationIndex]?.batteryLevel / 1000).toFixed(2)} Volts</span>
														))}*/}
												</SummaryBlock>
												<SummaryTime />
											</>
										)}
									</MarkerInfo>
								</SummaryContainer>

								{polylines && polylines.length && (
									<AnimationControls>
										<Button
											className={'playPause'}
											onClick={() => {
												animationOn.current = !animationOn.current;
												if (animationOn.current) {
													animationTurnedOn(animationIndex).catch((error) => {
														handleError('Track.tsx => animationTurnedOn()', error);
													});
													if (mapInstance) {
														setBounds(mapInstance);
													}
												}
											}}>
											<Icon className={'animationIcon'} name={animationOn.current ? 'pause' : 'play'} />
										</Button>
										<input
											type={'range'}
											id={'seek'}
											onChange={(event) => {
												animationOn.current = false;
												showAnimationInfoWindow.current = false;
												setAnimationIndex(parseInt(event.target.value));
												setAnimationPath(paths[parseInt(event.target.value)]);
											}}
											min={0}
											max={paths?.length - 1}
											value={animationIndex}
										/>
										<Button
											// icon
											// circular
											className={'stop'}
											active={false}
											onClick={() => {
												setAnimationIndex(0);
												setAnimationPath(paths[0]);
												animationOn.current = false;
												showAnimationInfoWindow.current = false;
											}}>
											<Icon className={'animationIcon'} name="stop" />
										</Button>
										<div
											style={{
												width: '4em',
												border: 'none',
												borderRadius: '2px',
												marginRight: '1em',
											}}>
											<Dropdown
												defaultValue={trackSpeed.current}
												fluid
												onChange={(e, { value }) => {
													if (typeof value === 'number') trackSpeed.current = value;
												}}
												selection
												options={TRACK_SPEED_DROPDOWN}
											/>
										</div>
									</AnimationControls>
								)}
							</>
						)}
				</GoogleMap>
				{/*</LoadScript>*/}

				{loading && (
					<Placeholder>
						<Loader active inline />
					</Placeholder>
				)}

				{!loading && !sideBarState.selectedVehicles.length && (
					<Transition visible animation="scale" duration={500}>
						<Placeholder>
							<span>Please select a vehicle.</span>
						</Placeholder>
					</Transition>
				)}

				{!loading && sideBarState.selectedVehicles.length > 1 && (
					<Transition visible animation="scale" duration={500}>
						<Placeholder>
							<span>Please select only one vehicle.</span>
						</Placeholder>
					</Transition>
				)}

				{/*No Data*/}
				{!loading && sideBarState.selectedVehicles.length === 1 && paths?.length === 0 && !show7DaysPlaceholder && (
					<Transition visible animation="scale" duration={500}>
						<Placeholder>
							<span>No Data</span>
						</Placeholder>
					</Transition>
				)}

				{!loading && sideBarState.selectedVehicles.length === 1 && paths?.length === 0 && show7DaysPlaceholder && (
					<Transition visible animation="scale" duration={500}>
						<Placeholder>
							<span>Please Select Only 7 Days</span>
						</Placeholder>
					</Transition>
				)}
			</Container>
		);
	} else return <TrackNerdLoader />;
};

export default Track;
