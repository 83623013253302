import axios from 'axios';
import { trace } from 'firebase/performance';
import { performanceRef } from './firebase';

/*import { datadogLogs } from '@datadog/browser-logs';*/

export function getData(URL: string, params = '') {
	const t = trace(performanceRef, `GET_${URL}`);
	t.start();

	/*datadogLogs.logger.log(`GET: ${URL + params}`);*/

	const response = axios.get(URL + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

export function postData(URL: string, params = '', body = {}) {
	const t = trace(performanceRef, `POST_${URL}`);

	t.start();

	/*datadogLogs.logger.log(`POST: ${URL + params}`, body);*/

	const response = axios.post(URL + params, body, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

export function exportPostData(URL: string, params = '', body = {}) {
	const t = trace(performanceRef, `POST_${URL}`);

	t.start();

	/*datadogLogs.logger.log(`EXPORT POST: ${URL + params}`, body);*/

	const response = axios.post(URL + params, body, {
		responseType: 'arraybuffer',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

export function getFileData(URL: string, params = '') {
	const t = trace(performanceRef, `GET_FILES_${URL}`);

	t.start();

	/*datadogLogs.logger.log(`GET FILE: ${URL + params}`);*/

	const response = axios(URL + params, {
		responseType: 'arraybuffer',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
	t.stop();
	return response;
}
