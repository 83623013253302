import React from 'react';
import AppGateway from './AppGateway';
import Store from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';

// Theme
import { THEME } from './constants/Theme';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

import { initializeMixpanel } from './helper/services/mixpanel';
/*import { initializeDataDog } from './helper/services/dataDog';*/
import Mobile from './components/mobileUser/Mobile';
import AssetVerification from './components/verification/AssetVerification';
import { isVerificationLink } from './helper/services/common';
import { initializeFirebase } from './helper/services/firebase';

function App() {
	initializeFirebase();
	initializeMixpanel();
	/*initializeDataDog();*/

	return (
		<Provider store={Store}>
			<ThemeProvider theme={THEME}>
				{isVerificationLink() ? (
					<AssetVerification url={window.location.search} />
				) : isMobile ? (
					<div>
						{isAndroid && <Mobile isAndroid={true} />}
						{isIOS && <Mobile isAndroid={false} />}
					</div>
				) : (
					<AppGateway />
				)}
				<ToastContainer />
			</ThemeProvider>
		</Provider>
	);
}

export default App;
