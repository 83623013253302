import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { VEHICLE_STATES } from '../../../constants/status-code';
import {
	Address,
	Battery,
	Container,
	Controls,
	Distance,
	FlexContainer,
	Header,
	LastUpdate,
	RegNo,
	SpaceBetween,
	Speed,
	VehicleStatus,
} from './CustomInfoWindowStyles';
import { isoToCustom, isoToHumanReadable } from '../../../utils/calendar';
import { openTrackingLinkModal } from '../../../views/dashboard/vehicles/main/location/LocationMiddleware';
import { Expander } from '../../STYLED/Expander';
import { THEME } from '../../../constants/Theme';
import { Status } from '../../STYLED/VehicleListStyles';
import { updateVehicleStatus } from '../../../helper/view specific/vehicleStatus';
import { RootState } from '../../../redux/RootState';
import { TrackDTO } from '../../../dtos/track';
import { TripRouteData } from '../../../dtos/tripRoute';
import { GiCartwheel } from 'react-icons/gi';
import { AddressLink, Coordinates } from '../../../utils/common';

interface PropType {
	id: number;
	coordinates?: Coordinates;
	content?: TrackDTO | TripRouteData | null;
	RegistrationNo?: boolean;
	VehicleState?: boolean;
	immobilizer?: boolean;
	liveStatus?: boolean;
	relay?: boolean;
	shareTrackingLink?: boolean;
	recalculation?: boolean;
	address?: string;
	liveAddress?: boolean;
	speed?: boolean;
	liveSpeed?: boolean;
	distance?: boolean;
	liveDistance?: boolean;
	liveDistanceData?: number;
	liveBattery?: boolean;
	liveDTE?: boolean;
	liveSOC?: boolean;
	lastUpdate?: boolean;
	liveOdometer?: boolean;
	liveFuel?: boolean;
	liveTimestampUpdate?: boolean;
	relayIconShow?: boolean;
	distanceFromLastAddress?: number | null;
	googleAddress?: string;
	onRefreshAddress?: () => void;
	onVehicleImmobilize?: () => void;
	onVehicleMobilize?: () => void;
	onVehicleKeyLockClick?: () => void;
	onVehicleKeyUnlockClick?: () => void;
	onIgnitionOnClick?: () => void;
	onIgnitionOffClick?: () => void;
	onWheelLockOnClick?: () => void;
	onWheelLockOffClick?: () => void;
	getVehiclesforDropdown?: () => void;
}

const CustomInfoWindow = (props: PropType) => {
	const {
		id,
		coordinates,
		content,
		RegistrationNo,
		VehicleState,
		liveStatus,
		relay,
		shareTrackingLink,
		recalculation,
		address,
		liveAddress,
		speed,
		liveSpeed,
		distance,
		liveDistance,
		liveDistanceData,
		//liveDTE,
		//liveSOC,
		lastUpdate,
		liveTimestampUpdate,
		relayIconShow,
	} = props;

	const vehicleDetails = useSelector((state: RootState) => state.vehicle.vehicleDetails);
	const vehicleState = useSelector((state: RootState) => state.vehicleSlice.entities);
	const permissionOrg = useSelector((state: RootState) => state.rolePermissions.permissions.Organisation);

	let vehicleStatus: string = '';
	const locationOfThisVehicle = vehicleState[id]?.location;
	if (locationOfThisVehicle) {
		vehicleStatus = vehicleState[id]?.location.timestamp ? updateVehicleStatus(locationOfThisVehicle) : '';
	}

	const BatteryValue = () => {
		const externalPowerVoltage = vehicleState[id]?.location?.externalPowerVoltage;
		const batteryLevel = content?.batteryLevel;

		if (externalPowerVoltage !== undefined) {
			return <Battery>Battery {(externalPowerVoltage / 1000).toFixed(2)} Volts</Battery>;
		} else {
			return <Battery>Battery {batteryLevel ? (batteryLevel / 1000).toFixed(2) : 0} Volts</Battery>;
		}
	};

	// const SOCValue = () => {
	// 	if (vehicleState[id]?.location?.soc !== 0 && liveSOC) {
	// 		return <SOC>SOC {vehicleState[id]?.location?.soc} %</SOC>;
	// 	} else if (content) {
	// 		return content?.soc && <SOC>SOC {content?.soc} %</SOC>;
	// 	} else {
	// 		return <></>;
	// 	}
	// };

	// const DTEValue = () => {
	// 	if (vehicleState[id]?.location?.dte !== 0 && liveDTE) {
	// 		return <DTE>DTE {content?.dte} kms</DTE>;
	// 	} else if (content) {
	// 		return content?.dte && <DTE>DTE {content?.dte} kms</DTE>;
	// 	} else {
	// 		return <></>;
	// 	}
	// };

	const LastUpdated = () => {
		const location = vehicleState[id]?.location;
		if (
			(lastUpdate === undefined || lastUpdate) &&
			liveTimestampUpdate &&
			vehicleState &&
			location &&
			Object.hasOwn(location, 'timestamp')
		) {
			if (vehicleStatus === 'No data since') {
				return (
					<LastUpdate style={{ color: THEME.COLORS.vehicleNoDataHalfHour }}>
						Last data {isoToHumanReadable(location.timestamp)}
					</LastUpdate>
				);
			} else {
				return <LastUpdate>{isoToHumanReadable(location.timestamp)}</LastUpdate>;
			}
		} else if (content) {
			const timestamp = content.timestamp;

			if (vehicleStatus === 'No data since') {
				return (
					<LastUpdate style={{ color: THEME.COLORS.vehicleNoDataHalfHour }}>
						Last data {isoToHumanReadable(timestamp)}
					</LastUpdate>
				);
			} else {
				return <LastUpdate>{isoToHumanReadable(timestamp)}</LastUpdate>;
			}
		} else {
			return <></>;
		}
	};

	const Recalculation = () => {
		const recalculationLocal = vehicleState[id]?.recalculation;

		if (
			(recalculation === undefined || recalculation) &&
			recalculationLocal &&
			recalculationLocal?.from &&
			recalculationLocal?.startTime &&
			recalculationLocal?.percentage !== undefined
		) {
			return (
				<Popup
					trigger={<Icon name="hourglass half" circular color="orange" />}
					content={
						<div>
							{`Recalculating\nFrom : ${isoToCustom(recalculationLocal.from, 'DD-MM-YYYY')}\nStarted at : ${isoToCustom(
								recalculationLocal?.startTime,
								'DD-MM-YYYY'
							)}/${isoToCustom(recalculationLocal?.startTime, 'hh:mm:ss A')}\n${
								recalculationLocal?.percentage >= 0 ? `Percentage : ${recalculationLocal?.percentage}%` : ''
							}`}
						</div>
					}
					on="hover"
					position="bottom center"
					hoverable
				/>
			);
		}
	};

	const ShowAddress = () => {
		if (address === undefined || address) {
			if (liveAddress && coordinates) {
				return (
					<Address>
						{props.distanceFromLastAddress && (
							<>
								<Icon name="chevron left" color="orange" />
								<span>{props.distanceFromLastAddress} m from</span>
								<Icon name="chevron right" color="orange" />
							</>
						)}
						<AddressLink
							value={props.googleAddress !== undefined ? props.googleAddress : ''}
							coordinates={coordinates}
							infoWindow={true}
						/>
						{/*<span title={props.googleAddress}>{props.googleAddress}</span>*/}
						{props.distanceFromLastAddress && (
							<span>
								<Button color="blue" onClick={props.onRefreshAddress}>
									Refresh
								</Button>
							</span>
						)}
					</Address>
				);
			} else {
				if (coordinates) {
					return (
						<Address>
							<AddressLink
								value={props.googleAddress !== undefined ? props.googleAddress : ''}
								coordinates={coordinates}
								infoWindow={true}
							/>
						</Address>
					);
				} else return <></>;
			}
		} else return <> </>;
	};

	const DistanceInKm = () => {
		if ((distance === undefined || distance) && content) {
			const distance =
				liveDistance && liveDistanceData
					? Number((liveDistanceData / 1000).toFixed(2)).toLocaleString()
					: Number((content?.distance / 1000).toFixed(2)).toLocaleString();

			return <Distance>Distance {distance} km</Distance>;
		} else {
			return '';
		}
	};

	return (
		<Container>
			<Header>
				<section>
					{(RegistrationNo === undefined || RegistrationNo) && (
						<RegNo>{vehicleDetails.get(id)?.data?.registrationNumber}</RegNo>
					)}
					{(VehicleState === undefined || VehicleState) && (
						<VehicleStatus color={vehicleDetails.get(id)?.data?.status}>
							{vehicleDetails.get(id)?.data?.status === VEHICLE_STATES.Breakdown
								? 'Breakdown'
								: vehicleDetails.get(id)?.data?.status === VEHICLE_STATES.Paused
									? 'Paused'
									: ''}
						</VehicleStatus>
					)}

					{liveStatus ? (
						<Status
							color={
								vehicleStatus === 'GPS not fixed'
									? THEME.COLORS.vehicleNoDataHalfHour
									: vehicleStatus === 'No data since'
										? THEME.COLORS.vehicleNoDataHalfHour
										: vehicleStatus === 'Moving'
											? THEME.COLORS.vehicleMoving
											: vehicleStatus === 'Idle'
												? THEME.COLORS.vehicleIdle
												: vehicleStatus === 'Ignition off'
													? THEME.COLORS.vehicleIgnitionOff
													: THEME.COLORS.vehicleNoDataHalfHour
							}>
							{vehicleStatus !== 'No data since' && vehicleStatus}
						</Status>
					) : content?.ignitionStatus === false ? (
						<Status color={THEME.COLORS.vehicleIgnitionOff}>Ignition Off</Status>
					) : content?.speed === 0 ? (
						<Status color={THEME.COLORS.warning}>Idle</Status>
					) : (
						<Status color={THEME.COLORS.vehicleMoving}>Moving</Status>
					)}
				</section>
				<Controls>
					{(shareTrackingLink === undefined || shareTrackingLink) && (
						<Icon
							name="share alternate"
							circular
							color="blue"
							title="Share Tracking Link"
							link
							onClick={() => {
								openTrackingLinkModal();
								props?.getVehiclesforDropdown?.();
							}}
						/>
					)}
					{permissionOrg.create || permissionOrg.manage ? (
						(vehicleDetails.get(id)?.data?.sensors?.includes('relay') ||
							vehicleDetails.get(id)?.data?.sensors?.includes('inverted_relay')) && (
							<div>
								{relayIconShow && !vehicleState[id]?.location.relayStatus && (
									<Icon
										name="lock open"
										circular
										disabled={
											vehicleState[id]?.relay?.requestPending !== undefined &&
											vehicleState[id]?.relay?.requestPending !== 0
										}
										color="blue"
										title={vehicleState[id]?.relay?.requestPending === 1 ? 'Request in process' : 'Immobilize Vehicle'}
										link={!(vehicleState[id]?.relay?.requestPending && vehicleState[id]?.relay?.requestPending !== 0)}
										onClick={props.onVehicleImmobilize}
									/>
								)}

								{relayIconShow && relay === undefined && vehicleState[id]?.location.relayStatus && (
									<Icon
										name="lock"
										circular
										disabled={
											vehicleState[id]?.relay?.requestPending !== undefined &&
											vehicleState[id]?.relay?.requestPending !== 0
										}
										color="red"
										title={vehicleState[id]?.relay?.requestPending === 1 ? 'Request in process' : 'Mobilize Vehicle'}
										link={!(vehicleState[id]?.relay?.requestPending && vehicleState[id]?.relay?.requestPending !== 0)}
										onClick={props.onVehicleMobilize}
									/>
								)}
							</div>
						)
					) : (
						<Icon name="ban" circular color="red" title={'Immobilizer access denied'} />
					)}

					{vehicleDetails.get(id)?.data?.sensors?.includes('wheellock') && (
						<Popup
							style={{ zIndex: '10' }}
							disabled={
								vehicleState[id]?.wheellock?.requestPending !== undefined &&
								vehicleState[id]?.wheellock?.requestPending === 1
							}
							trigger={
								<div
									title={
										vehicleState[id]?.wheellock?.requestPending !== undefined &&
										vehicleState[id]?.wheellock?.requestPending === 1
											? 'Request in process'
											: 'Wheel Lock'
									}
									aria-disabled={true}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '50%',
										border: '1.5px solid #EAEAEA',
										height: '25px',
										width: '25px',
										marginRight: '2.5px',
									}}>
									<GiCartwheel color={'#2285D0'} fontSize={'18px'} />
								</div>
							}
							on={'hover'}
							position={'bottom center'}
							hoverable={true}
							content={
								<div style={{ fontWeight: 'bold' }}>
									<Icon name={'lock'} onClick={props.onWheelLockOnClick} color={'red'} />
									<Icon name={'unlock'} onClick={props.onWheelLockOffClick} color={'blue'} />
								</div>
							}
						/>
					)}

					{vehicleDetails.get(id)?.data?.sensors?.includes('ignition') && (
						<>
							{!vehicleState[id]?.location.ignitionStatus && (
								<Icon
									circular
									name={'bolt'}
									color={'blue'}
									disabled={
										vehicleState[id]?.ignition?.requestPending !== undefined &&
										vehicleState[id]?.ignition?.requestPending === 1
									}
									title={
										vehicleState[id]?.location.ignitionStatus
											? 'Ignition on request sent to vehicle'
											: 'Turn On Ignition'
									}
									// link={!(remoteIgnitionStatus?.[id]?.requestPending && remoteIgnitionStatus?.[id]?.requestPending !== 0)}
									onClick={props.onIgnitionOnClick}
								/>
							)}
							{vehicleState[id]?.location.ignitionStatus && (
								<Icon
									circular
									name={'bolt'}
									color={'red'}
									disabled={
										vehicleState[id]?.ignition?.requestPending !== undefined &&
										vehicleState[id]?.ignition?.requestPending === 1
									}
									title={
										vehicleState[id]?.ignition?.requestPending === 1
											? 'Ignition off request sent to vehicles'
											: 'Turn Off Ignition'
									}
									// link={!(remoteIgnitionStatus?.[id]?.requestPending && remoteIgnitionStatus?.[id]?.requestPending !== 0)}
									onClick={props.onIgnitionOffClick}
								/>
							)}
						</>
					)}

					<Recalculation />
				</Controls>
			</Header>

			<ShowAddress />

			<SpaceBetween>
				<DistanceInKm />

				{speed === undefined || speed ? (
					liveSpeed ? (
						vehicleState &&
						Object.hasOwn(vehicleState, id) && (
							<Speed>Speed {Number(vehicleState?.[id]?.location?.speed).toFixed(2)} km/h</Speed>
						)
					) : (
						<Speed>Speed {Number(content?.speed).toFixed(2)} km/h</Speed>
					)
				) : (
					''
				)}
			</SpaceBetween>
			<BatteryValue />
			{/*<SOCValue />*/}
			{/*<DTEValue />*/}

			<FlexContainer>
				<LastUpdated />
				<Expander />
			</FlexContainer>
		</Container>
	);
};

export default CustomInfoWindow;
