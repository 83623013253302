import styled from 'styled-components';

import { VEHICLE_STATES } from '../../../constants/status-code';
import { THEME } from '../../../constants/Theme';

interface InfoCardStatusProps {
	paddingBottom: string;
}

export const Container = styled.section`
	min-width: 18em;
	max-width: 22em;
`;

export const Header = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const RegNo = styled.section`
	font-size: 1em;
	font-weight: bold;
`;

export const VehicleStatus = styled.section`
	font-size: 0.75em;
	font-weight: bold;
	line-height: normal;
	margin: 2px 0;

	color: ${(props) =>
		props.color === VEHICLE_STATES.Breakdown
			? THEME.COLORS.Breakdown
			: props.color === VEHICLE_STATES.Paused
				? THEME.COLORS.Paused
				: ''};
`;

export const Status = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	color: ${(props) => props.color};
`;

export const Controls = styled.section`
	margin: 0.5em 0;
	display: flex;
`;

export const Driver = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	i.icon {
		height: 100%;

		margin: 0 !important;
		padding-top: 0.1em;
		box-sizing: border-box;
	}

	span {
		display: inline-block;

		font-size: 0.9em;
		font-weight: bold;
	}
`;

export const Address = styled.section`
	margin: 0.5em 0 0.5em 0;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 0.9em;
	font-weight: bold;
	color: ${(props) => props.theme.COLORS.theme};

	button {
		margin-left: 0.5em !important;
		padding: 0.25em 0.5em !important;
		border-radius: 2em !important;
		font-size: 0.95em !important;
	}
`;

export const Odometer = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;

	i.icon {
		margin: 0 !important;
	}

	span {
		display: inline-block;

		font-size: 0.9em;
		font-weight: bold;
	}
`;

export const FlexContainer = styled.section`
	display: flex;
`;

export const SpaceBetween = styled.section`
	display: flex;
	justify-content: space-between;
`;

export const Speed = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const Battery = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const SOC = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const DTE = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const Altitude = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const LastUpdate = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const Distance = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const Fuel = styled.section`
	font-size: 0.9em;
	font-weight: bold;
`;

export const InfoCardStatus = styled.section<InfoCardStatusProps>`
	font-size: 0.9em;
	font-weight: bold;
	padding-bottom: ${(props) => props.paddingBottom};
	color: ${(props) => props.color};
`;
