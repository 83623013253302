import { GET_OTP_API, LOGIN_API, LOGOUT_API, VERIFY_OTP_API } from '../../constants/api';
import Store from '../../redux/store';
import { handleError, showMessage } from './messaging';
import { dispatchNoPayload } from './store';
import axios from 'axios';
import { MessageType } from '../../constants/message-type';
import { resetMixpanel, trackMixpanelEvent } from '../services/mixpanel';
import { postData } from '../services/axios';
/*import { datadogLogs } from '@datadog/browser-logs'; // Login*/

// Login
interface Permission {
	create: boolean;
	read: boolean;
	manage: boolean;
	update: boolean;
}

interface PermissionsObj {
	Vehicle: Permission;
	Document: Permission;
	Geofence: Permission;
	User: Permission;
	Organisation: Permission;
}

interface Role {
	name: string;
	isSuperAdmin: boolean;
	permissions: Array<{
		subject: keyof PermissionsObj;
		action: keyof Permission;
	}>;
}

interface Organisation {
	id: string;
	name: string;
}

interface UserDetailsResponse {
	id: string;
	name: string;
	phone: string;
	email: string;
	organisation: Organisation;
	role: Role;
	isEnabledEmail: boolean;
	isEnabledPush: boolean;
	isEnabledSMS: boolean;
	phoneVerified: boolean;
	emailVerified: boolean;
}

export function login(username: string, password: string) {
	startLoading();

	axios
		.post(
			LOGIN_API,
			{
				username: username ? username.trim() : username,
				password: password ? password.trim() : password,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((response) => {
			if (response.status === 200) {
				setUserDetails(response);
				dispatchLogin();
				finishLoading();
				trackMixpanelEvent('sign_in_via_password');
			}
		})
		.catch((error) => {
			finishLoading();
			handleError('auth.ts => login(): ', error);
		});
}

function startLoading() {
	Store.dispatch({
		type: 'LOADING',
	});
}

function finishLoading() {
	Store.dispatch({
		type: 'LOADED',
	});
}

function setUserDetails(response: {
	data: {
		user: UserDetailsResponse;
		token: string;
	};
}) {
	let permissions = {};

	const { id, name, phone, email, organisation, role } = response.data.user;
	const permissionsObj: PermissionsObj = {
		Vehicle: {
			create: false,
			read: false,
			manage: false,
			update: false,
		},
		Document: {
			create: false,
			read: false,
			manage: false,
			update: false,
		},
		Geofence: {
			create: false,
			read: false,
			manage: false,
			update: false,
		},
		User: {
			create: false,
			read: false,
			manage: false,
			update: false,
		},
		Organisation: {
			create: false,
			read: false,
			manage: false,
			update: false,
		},
	};

	if (role?.permissions.length) {
		role?.permissions.map((permission) => {
			const { subject, action } = permission;
			if (permissionsObj[subject]) {
				permissionsObj[subject][action] = true;
			}
		});
		permissions = permissionsObj;
	}

	localStorage.setItem('userId', id);
	localStorage.setItem('organisation', organisation.id);
	localStorage.setItem('organisationName', organisation.name);
	localStorage.setItem('name', name);
	localStorage.setItem('phone', phone);
	localStorage.setItem('email', email);
	localStorage.setItem('token', response.data.token);
	localStorage.setItem('roleName', role.name);
	localStorage.setItem('isSuperAdmin', JSON.stringify(role.isSuperAdmin));
	if (Object.keys(permissions).length) localStorage.setItem('permissions', JSON.stringify(permissions));
	localStorage.setItem('isEnabledEmail', JSON.stringify(response.data.user.isEnabledEmail));
	localStorage.setItem('isEnabledPush', JSON.stringify(response.data.user.isEnabledPush));
	localStorage.setItem('isEnabledSMS', JSON.stringify(response.data.user.isEnabledSMS));
	localStorage.setItem('phoneVerified', JSON.stringify(response.data.user.phoneVerified));
	localStorage.setItem('emailVerified', JSON.stringify(response.data.user.emailVerified));
}

function dispatchLogin() {
	Store.dispatch({
		type: 'LOGIN',
	});
}

export async function getOTP(phone: string) {
	Store.dispatch({ type: 'AUTH_SEND_OTP_REQUEST' });

	axios(GET_OTP_API + `/${phone}`, { headers: { 'Content-Type': 'application/json' } })
		.then(() => {
			Store.dispatch({ type: 'AUTH_SEND_OTP_RESOLVE' });
			showMessage('OTP sent.', MessageType.Success);
		})
		.catch((error) => {
			Store.dispatch({ type: 'AUTH_SEND_OTP_REJECT' });
			/*datadogLogs.logger.error('Get Otp Api Error', {}, error);*/
			handleError('auth.ts => getOTP(): ', error);
		});
}

export async function verifyOTP(phone: string, otp: string) {
	Store.dispatch({ type: 'AUTH_VERIFY_OTP_REQUEST' });

	axios
		.post(
			VERIFY_OTP_API + `/${phone}`,
			{
				otp: parseInt(otp, 10),
			},
			{ headers: { 'Content-Type': 'application/json' } }
		)
		.then((response) => {
			setUserDetails(response);
			dispatchLogin();
			finishLoading();
			Store.dispatch({ type: 'AUTH_VERIFY_OTP_RESOLVE' });
			trackMixpanelEvent('sign_in_via_otp');
		})
		.catch((error) => {
			/*datadogLogs.logger.error('Verify OTP Api Error', {}, error);*/
			finishLoading();
			Store.dispatch({ type: 'AUTH_VERIFY_OTP_REJECT' });
			handleError('auth.ts => verifyOTP(): ', error);
		});
}

// Session
export function handleUserSession() {
	if (localStorage.getItem('token')) {
		Store.dispatch({
			type: 'LOGIN',
		});
	}
}

// Logout
export async function logout() {
	const idFromLocalStorage = localStorage.getItem('flee-status-id');
	const id = idFromLocalStorage ? parseInt(idFromLocalStorage) : 0;
	if (id) clearInterval(id);
	sendLogoutRequest();
	const summaryColumns = localStorage.getItem('summaryColumns');
	localStorage.clear();
	if (summaryColumns !== 'null' && summaryColumns) localStorage.setItem('summaryColumns', summaryColumns);

	Store.dispatch({ type: 'CLEAR_NOTIFICATION_PERMISSIONS' });
	dispatchNoPayload('SIDEBAR_UNMOUNT');

	Store.dispatch({
		type: 'LOGOUT',
	});
}

function sendLogoutRequest() {
	if (localStorage.getItem('token')) {
		postData(LOGOUT_API)
			.then(() => {
				resetMixpanel();
			})
			.catch((error) => {
				/*datadogLogs.logger.error('Logout Api Error', {}, error);*/
				handleError('auth.ts => sendLogoutRequest(): ', error);
			});
	}
}
